#tts-demo {
    &.content-image-wrapper {
        position: relative;
        max-width: 556px;
        min-width: 280px;
        min-height: 343px;
        height: fit-content;
        border-radius: 9px;
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #2689ff;
        background-color: #ffffff;
        border-style: dashed;
        display: flex;
        flex-direction: column;
        stroke-dashoffset: 62;
        p {
            max-width: 287px;
            font-size: 16px !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: 0.11px;
            color: rgba(24, 24, 26, 0.3);
            text-align: center;
            padding-top: 20px;
        }
        img {
            width: 83px;
            height: auto;
        }
    }

    .btn-record {
        // position: relative;
        width: 83px !important;
        height: 83px !important;
        margin: unset;
        // background-color: #3372CA;
        // border-radius: 50% !important;
        // border: none;
        // transition: 0.3s ease background-color;
    }

    .dropdown-container {
        text-align: left;
        border: 1px solid #ccc;
        position: relative;
        border-radius: 5px;
        margin: 10px;
    }

    .dropdown-input {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
    }

    .dropdown_lang {
        border-radius: 20px !important;
        padding-left: 10px;
        width: 180px;
    }

    .dropdown_lang:hover {
        border-color: #39f;
        -webkit-box-shadow: 0px 0px 6px 0px #7cb7ff;
        -moz-box-shadow: 0px 0px 6px 0px #7cb7ff;
        box-shadow: 0px 0px 6px 0px #7cb7ff;
    }

    .dropdown-menu-language {
        position: absolute;
        z-index: 1;
        transform: translateY(4px);
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        overflow-y: auto;
        max-height: 150px;
        background-color: #fff;
    }

    .dropdown-item {
        padding: 5px;
        cursor: pointer;
    }

    .dropdown-item:hover {
        background-color: #9fc3f870;
    }
}

@media (max-width:375px) {
    #tts-demo {
        &.content-image-wrapper {
            min-width: 220px !important;
        }
        .dropdown_lang {
            width: 150px !important;
        }
    }
}