.billing-step-header {
    display: flex;
    justify-content: space-between;

    img {
        cursor: pointer;
    }

    .step-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        .step-dot {
            height: 11px;
            width: 11px;
            border-radius: 50%;
            background-color: #f0f0f0;
            margin: 0 9px;

            &.active {
                background-color: #2689ff;
            }
        }
    }
}