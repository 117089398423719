.account-page-main-container {
    height: 100%;
    background-color: #f5f6fa;
    
    .account-page-content-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 600px;
        max-width: 100%;
        height: fit-content;
        // padding: 6vh 10vw;
        padding: 6vh 20px;  
        margin: 0 auto;

        .head {
            // margin-bottom: 50px;
            margin-bottom: 25px;

            .title {
                font-size: 24px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.83;
                letter-spacing: -0.4px;
                text-align: left;
                color: #3372CA;
            }

            .caption {
                font-size: 18px;
                line-height: 1.53;
                letter-spacing: 0.09px;
                margin-bottom: 15px;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            min-height: 250px;
            height: 100%;
            border-radius: 25px;
            background-color: #ffffff;
            padding: 30px;
            overflow-x: auto;
            margin-bottom: 20px;
        }

        form {
            // font-size: 14px;
            display: flex;
            flex-direction: column;

            .row {
                margin: 0 -10px;
                [class*="col-"] {
                    padding: 0 10px;
                }
            }

            input {
                border-color: #ced4da;
                background-color: transparent;
                // font-size: 12px;

                &:focus {
                    border-color: #3372CA;
                }
            }

            label {
                // font-size: 12px;
            }

            .email {
                position: relative;
                .badge, a {
                    position: absolute;
                    right: 15px;
                    top: 28px;
                    font-weight: 500;
                }

                a {
                    display: none;
                    font-size: 75%;
                    cursor: pointer;
                }

                &:hover {
                    .badge {
                        display: none;
                    }
                    a {
                        display: block;
                    }
                }
            }
        }

        .create-password {
            margin: 10px 0;
            font-size: 13px;
        }
    }
}

.modal-confirm-password {
    .modal-body {
        padding: 40px;
    }

    .title {
        text-align: center;
        margin-bottom: 20px;
    }

    .button-group {
        display: flex;
        justify-content: flex-end;
        margin: 15px -5px 5px;
        // margin-top: 10px;

        button {
            height: 45px;
            width: 100%;
            margin: 0 5px;
            font-size: 14px;
        }
    }
}