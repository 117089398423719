.faq-container {
    padding: 50px 18vw 100px;
    display: flex;
    flex-direction: column;
    // max-width: 1300px;
    h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        /* or 150% */
        text-align: center;
        margin: 20px auto 100px;
        color: #202020;
    }
}

.faq-list-wrapper {
    display: flex;
    flex-direction: column;
}

.faq-item-cont {
    background: #FFFFFF;
    border: 1px solid #DCE0E8;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 28px 38px;
    margin-bottom: 15px;    
    cursor: pointer;
    .faq-ask {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 35px;
        color: #1789FB;
        display: flex;
        align-items: center;
        #icon-faq {
            margin-left: auto;
            width: 12px;
            height: auto;
        }
    }
    .faq-answer {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 35px;
        /* or 194% */

        letter-spacing: 0.136905px;

        color: #7B818C;
        margin-top: 13px;
    }
}

@media (max-width:768px) {
    .faq-container {
        padding: 50px 8vw 100px;        
    }

}