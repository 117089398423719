#your-service-detail-container {
    background-color: #f5f6fa;
    width: 100%;
    padding: 6vh 3vw;
  
    .head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 30px;
        vertical-align: middle;
        text-transform: capitalize;
    
        .back-btn {
            border: none;
            outline: none;
            text-decoration: none;
            background: transparent;
            margin-right: 15px;
            padding: 0;
    
            img {
                width: 34px;
                height: 34px;
            }
        }
        
        h3 {
            font-family: 'AirbnbCerealApp-Medium';
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.4px;
            color: #3372CA;
            margin: auto 0;
            margin-right: auto;
            padding-bottom: 5px;
            span {
                display: block;
                opacity: 0.9;
                font-family: 'AirbnbCerealApp-Medium';
                font-size: 18px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: -0.24px;
                color: #c1c1c1;
            }
        }
    
        .btn-app {
            margin-left: 15px;
            text-decoration: none;
    
            button {
                display: flex;
                align-items: center;
                position: relative;
                min-height: 50px;
                color: #ffffff;
                background-color: #3372CA;
                border: 1px solid #3372CA;
                padding: 8px 20px;
                // border-radius: 16px;
                font-weight: 400; 
                font-family: 'AirbnbCerealApp-Medium';
                font-size: 16px;
                text-transform: none;
                cursor: pointer;
        
                .icon {
                    margin-top: -4px;
                    margin-left: 5px;
                    font-size: 24px;
                }
            }
    
            .add-quota-btn {
                color: #3372CA;
                background-color: transparent;
            }
        }
    }
    .sub-head {
        margin-top: 20px;
        margin-bottom: 20px;
    
        button { 
            // float: right;  
            font-family: 'AirbnbCerealApp-Medium';
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            // line-height: 1.06;
            letter-spacing: 0.23px;
            color: #2689ff;
            border: none;
            outline: none;
            text-decoration: none;
            background: transparent;
            display: flex;
            align-items: center;
            margin: 0 0 0 auto;
            img {
                width: 30px;
                height: 30px;
            }
    
        }
    }
    .usage-history-container {
        // width: 642px;
        height: 700px;
        min-height: 531px;
        // max-height: 531px;
        border-radius: 25px;
        background-color: #ffffff;
        padding: 38px 39px 38px 40px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        // position: absolute;
  
        h4 {
            font-family: 'AirbnbCerealApp-Medium';
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            // line-height: 3.02;
            letter-spacing: -0.24px;
            color: #3372CA;
        }
  
        .usage-history-wrapper{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: auto;
            /* width */
            &::-webkit-scrollbar {
                width: 3.9px;
                cursor: grab;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background-color: #ffffff;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #c1c1c1;
                width: 3.9px;
                // height: 10px;
                border-radius: 3.5px;
                cursor: grab;
            }
        }
  
        .usage-history-bottom {
            padding-bottom: 0;
            margin-top: 40px;
        }
    }
  
    .side-card {
        // height: 531px;
        // min-height: 200px;
        // height: 100%;
        // max-height: 531px;
        border-radius: 25px;    
        background-color: #3372CA;
        padding: 18px 40px 28px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;

        .api-key-expires-countdown {
            // margin-left: auto;
            // margin-left: 20px;
            font-weight: normal;

            .badge {
                font-weight: normal;
            }
        }

        .side-card-title {
            font-family: 'AirbnbCerealApp-Bold';
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 3.27;
            letter-spacing: -0.25px;
            color: #ffffff;
        }
      
        .keys-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: -10px;
            margin-bottom: 0;
            font-family: 'AirbnbCerealApp-Bold';
            font-size: 30px;
            // font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.06;
            letter-spacing: -0.4px;
            color: #75e8e7;
    
            .keys {
                height: fit-content;
                min-height: fit-content;
                max-height: 129px;
                padding: 10px;
                font-size: 14px;
                line-height: 1.2;
                line-break: anywhere;
                overflow: auto;
            }
    
            .tools {
                margin-left: auto;

                & > * {
                    // margin-bottom: 8px;
                    // margin-left: 20px;
                    // padding: 10px;
                    margin: 10px;
                }
            }
    
            img {
                height: 25px;
                // margin: 0 0 0 auto;
                // margin-right: 20px;
                cursor: pointer;
            }
    
            button {
                color: #3372CA;
            }
        }
      
        .desc {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            font-family: 'AirbnbCerealApp-Book';
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.86;
            letter-spacing: 0.07px;
            color: #ffffff;
            // width: 210px;
            // margin-top: 8px;
        }
    
        .download-btn {
            // margin: 40px auto 0 auto;
            cursor: pointer;
        }
        .document-name {
            word-break: break-word;
            margin: 16px auto 0 auto;
            font-family: 'AirbnbCerealApp-book';
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.86;
            letter-spacing: 0.07px;
            color: #ffffff;
        }
        .bg-img {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 35%;
            // width: 100%;
            border-bottom-right-radius: 25px;
        } 
    }
  
}

@media (max-width:767px) {
    .detail-services-container {
      .sub-head {
        button {
          margin: 0 0 0 0;
        }
      }
    }
  }