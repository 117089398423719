#service-detail-container {
    // min-width: 558px;
    
    .main-header {

        h3, p, a {
            position: relative;
        }

        h3 {
            letter-spacing: 0.93px;
        }
        p, a {
            letter-spacing: 0.46px;
        }
    }

    .see-docs-btn {
        // position: absolute;
        // right: 0;
        width: fit-content;
        display: block;
        margin-bottom: 1rem;

        button {
            background-color: #ffffff;
            color: #3372CA;
            letter-spacing: 0.46px;
        }
    }

    .services-card {
        transform: unset !important;
        // transition: 0.2s ease height;
        cursor: default;
    }

    #biling-menu-container {
        padding: 0 ;
        margin-top: 10px;

        .title-desc-col {
            display: none;
        }
        .margin-auto {
            width: 100%;
        }

        .landing-wrapper,
        .landing-wrapper-2 {
            .margin-auto:last-child {
                margin-bottom: 10px;
            }        
        }

        .price-card {
            min-width: unset;
            max-width: 100%;
            min-height: unset !important;
            width: 460px;
            height: fit-content;
            padding: 25px 30px;
            margin: 0 auto;
            background-image: none !important;
            float: unset;

            .service-list-cont {
                margin-top: 15px;
            }

            #buy-btn {
                position: relative;
                margin: 0;
                margin-top: 15px;
            }
        }
    }

    #stt-demo,
    #tts-demo {
        margin: 10px 0 0 !important;
        max-width: unset;
        width: 100%;
        padding: 25px 30px;
        box-shadow: none;

        .content-default {
            // padding: 25px 30px;
            padding: 0;
        }

        .upload-btn,
        .convert-btn {
            height: unset;
            max-width: unset;
            margin: unset;
            margin-left: 10px;
            margin-top: 20px;
        }

        .upload-btn {
            margin-left: auto;
        }
    }
}