.features-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #f0f4f8;
    padding: 2rem 1.5rem;

    .features-card {
        background: linear-gradient(180deg, #3372CA 0%, #193864 100%);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 2rem;
        width: 100%;
        text-align: center;
    }

    .features-title {
        font-size: 2rem;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 1.5rem;
    }

    .features-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
        margin: 2rem 0;

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
        }

        .feature-item {
            display: flex;
            align-items: center;
            color: #ffffff;
            font-size: 1.25rem;

            .feature-icon {
                margin-right: 1rem;
                color: #ffffff;
                font-size: 1.5rem;
            }
        }
    }

    .divider {
        border: none;
        border-top: 2px solid #ffffff;
        margin: 1.5rem 0;
    }

    .coming-soon {
        .coming-soon-title {
            font-size: 1.5rem;
            font-weight: 600;
            color: #ffffff;
            margin-bottom: 0.5rem;
            text-align: left;
        }

        .coming-soon-list {
            list-style-type: disc;
            padding-left: 1.5rem;
            color: #e0e7ff;
            font-size: 1.1rem;
            text-align: left;

            .coming-soon-item {
                margin-bottom: 0.5rem;
            }
        }
    }
}
