#payment-container {
    font-family: 'AirbnbCerealApp-Bold';
    position: absolute;
    width: 100%;
    min-width: fit-content;
    min-height: 100%;
    padding: 6vh 10vw;  
    // padding-top: 40px;
    background-color: #ffffff;
    background-color: #f5f6fa;
    


    h3 {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.58;
        letter-spacing: 0.35px;
    }
    
    p {
        font-size: 18px;
        line-height: 1.53;
        letter-spacing: 0.09px;
    }
            
    hr {
        width: 100%;
        border-style: dashed;
    }

    .payment-header {
        display: flex;
        justify-content: space-between;
        min-width: 600px;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 3vh;

        img {
            cursor: pointer;
        }
    }

    .payment-content {
        // max-width: 600px;
        min-width: 600px;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        transform: unset;
        cursor: default;
        // text-align: center;

        // *[class^='col'] {
        //     padding-left: 2vw;
        //     padding-right: 2vw;
        // }

        .payment-content-col {
            padding: 15px;
        }

        .payment-card {
            min-height: 100%;
            // margin-bottom: 30px;
            padding: 30px 40px;
            transform: unset;
            cursor: default;
        }

        .header {
            margin-bottom: 15px;

            .title {
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.7;
                letter-spacing: -0.53px;
                color: #18181a;
            }
            
            .desc {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: 0.1px;
                color: rgba(24, 24, 26, 0.7);
            }
            
            hr {
                border-style: dashed;
            }
        }

        .payment-acc-info {
            margin-bottom: 40px;

            .acc-logo {
                margin: 0 auto 27px auto;
                // width: 150px;
                max-width: 150px;
            }

            .acc-number {
                font-size: 34px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.78;
                letter-spacing: 0.12px;
                text-align: center;
                color: #18181a;
                margin-bottom: 16px;
            }
            .acc-name {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.65;
                letter-spacing: 0.1px;
                text-align: center;
                color: #747474;
            }
        }

        .payment-total {
            margin-bottom: 30px;
            
            .payment-total-desc {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.65;
                letter-spacing: 0.1px;
                text-align: center;
                color: #18181a;
            }
            .payment-total-price {
                font-size: 24px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.1;
                letter-spacing: 0.14px;
                text-align: center;
                color: #f35745;
            }
        }

        .payment-plan-type {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 20px;
            margin: 0px auto 30px;
            border-radius: 9px;
            background-color: rgba(38, 137, 255, 0.06);

            &.confirmation {
                padding: 35px;
            }

            .payment-plan-type-name {
                margin-left: 30px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                text-align: left;
                color: #18181a;

                .title {
                    font-size: 13px;
                    opacity: 0.7;
                }

                .type {
                    text-transform: capitalize;
                }
                
                .price {  
                    font-size: 14.4px;

                    .unit, span {  
                        font-size: 12px;
                        opacity: 0.7;
                    }
                }

                .acc-number {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1.32;
                    letter-spacing: 0.12px;
                    color: #18181a;
                }

                .acc-name {
                    font-size: 14px;
                    line-height: 1.89;
                    letter-spacing: 0.1px;
                    color: rgba(24, 24, 26, 0.7);
                }
            }
        }
        .payment-form {
            font-size: 17.6px;
            line-height: 1.5;
            letter-spacing: 0.1px;
            margin-bottom: 10px;
            margin-bottom: 0;

            label {
                display: flex;
                align-items: center;
            }
            

            .form-group {
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;

                .form-label {
                    color: rgba(24, 24, 26, 0.7);
                }

                .form-control {
                    font-weight: 500;
                    color: #2689ff;
                }

                &.engine {
                    .right {
                        font-weight: 500;
                        color: #18181a;
                    }
                }

                &.payment-method {
                    img {
                        max-width: 100px;
                    }
                }

                &.total-amount {
                    // display: flex;
                    // flex-direction: column;
                    text-align: center;
                    // padding-top: 10px;
                    margin-bottom: 5px;

                    .form-label {
                        font-weight: bold;
                        color: #18181a;
                        margin-bottom: 0;
                    }

                    .right {
                        font-size: 24px;
                        font-weight: 500;
                        color: #f35745;
                    }
                }
                .input-duration {
                    color: #2689ff;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    img {
                        margin-left: 18px;
                        width: 20px;
                        cursor: pointer;
                    }
                    .operand-btn {
                        height: 30px;
                        width: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    #input-ammount {
                        // max-width: 70px;
                        height: 30px;
                        width: 70px;
                        // margin-right: 5px;
                        text-align: right;
                        margin: 0 5px;
                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                    -moz-appearance: textfield;
                    }
                }
            }
            
            hr {
                border-style: dashed;
            }

            select {
                width: 180px;
                border: none;
                &:active, &:focus {
                    box-shadow: none !important;
                }
            }            
        }

        .payment-deadline {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.36;
            letter-spacing: 0.1px;
            text-align: center;
            color: #18181a;
            margin-top: 8px;

            span {
                font-weight: bold;
                color: #18181a;
            }
        }

        .drag-and-drop-area {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 284px;
            border-radius: 9px;
            border: dashed 1px #2689ff;
            background-color: rgba(38, 137, 255, 0.2);
            margin-bottom: 40px;
            font-size: 18px;
            color: #18181a;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            input[type=file] {
                display: none;
            }

            img {
                margin-bottom: 10px;
            }

            .file-types {
                font-size: 13px;
                line-height: 1.54;
                letter-spacing: 0.46px;
                color: #2689ff;
                margin-bottom: 5px;
            }
        }

        .btn-payment {
            width: 100%;
            border-radius: 5px;
            background-color: #2689ff;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.02;
            letter-spacing: 0.28px;
            color: #ffffff;
            padding: 20px;
            margin-top: 10px;
            margin-bottom: 5px;
        }

        .how-to-pay-btn {
            display: block;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.06;
            letter-spacing: 0.1px;
            text-align: center;
            margin-top: 15px;
            cursor: pointer;
        }
    }

    .payment-detail-container {
        text-align: center;
    }
}

.close-payment {
    position: fixed;
    right: 10vw;
    background-color: transparent;
}

@media (max-width:400px) {
    #payment-container {
        .payment-content {
            .payment-plan-type {
                display: block;
                .payment-plan-type-name {
                    margin-top: 20px;
                    text-align: center;
                    h5,p {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
