// #biling-menu-container {
//     // text-align: center;
//     padding: 6vh 5vw;
//     padding-top: 40px;
//     width: 100%;
//     // max-width: 1300px;
//     margin: 0 auto;

//     .spinner {
//         position: absolute;
//         top: 49%;
//         left: 49%;
//     }

//     .billing-menu-header {
//         display: flex;
//         justify-content: space-between;

//         img {
//             cursor: pointer;
//         }
//     }

//     // content Pricing
//     .content-pricing {
//         width: 100%;
//         padding-top: 34px;
//         display: flex;
//         justify-content: center;
//         margin: 0;

//         &.large {
//             padding-top: 0;
//         }

//         .content-item-pricing-card {
//             margin: 15px 0;
//             padding: 0 30px;
//         }

//         .content-item-pricing {  
//             display: flex;
//             flex-direction: column;          
//             margin-top: 0;
//             // position: relative;        
//             width: 100%;
//             min-height: fit-content;
//             height: 100%;
//             padding: 45px 35px;
//             border-radius: 17px;
//             box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
//             background-color: #ffffff;

//             .header {
//                 color: #18181a;
//                 padding-bottom: 30px;

//                 img {
//                     margin-bottom: 30px;
//                 }

//                 .title {
//                     font-size: 20px;
//                     font-weight: bold;
//                     font-stretch: normal;
//                     font-style: normal;
//                     // line-height: 2.72;
//                     letter-spacing: -0.27px;
//                     color: rgba(24, 24, 26, 0.3);
//                     margin-bottom: 9px;
//                 }

//                 .price {
//                     font-size: 35px;
//                     font-weight: bold;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: 1.55;
//                     letter-spacing: -0.46px;
//                     color: #153a82;
//                     margin-bottom: 10px;
//                 }

//                 .discount {
//                     font-size: 15px;
//                     font-weight: normal;
//                     font-stretch: normal;
//                     font-style: normal;
//                     // line-height: 1.6;
//                     letter-spacing: 0.09px;
//                     color: rgba(24, 24, 26, 0.7);
//                     text-decoration: line-through;
//                     // text-decoration-color: rgb(162, 2, 2);
//                 }
//                 span {
//                     font-size: 15px;
//                     font-weight: normal;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: 1.6;
//                     letter-spacing: 0.09px;
//                     // color: rgba(24, 24, 26, 0.7);
//                     color: #7B818C;
//                 }

//                 .desc {
//                     font-size: 15px;
//                     font-weight: normal;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: 1.6;
//                     letter-spacing: 0.09px;
//                     color: rgba(24, 24, 26, 0.7);
//                 }
//             }

//             .service-list-container {
//                 height: 100%;
//                 margin-bottom: 30px;
//                 // color: rgba(24, 24, 26, 0.7);
//                 color: #7B818C;

//                 .service-list {
//                     font-size: 18px;
//                     font-weight: normal;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: 1.33;
//                     letter-spacing: 0.11px;
//                     margin-bottom: 15px;

//                     span {
//                         margin-left: 18px;
//                     }

//                     &.disabled {
//                         color: rgba(24, 24, 26, 0.2);
//                     }
//                 }
//             }
//             button {
//                 width: 187px;
//                 min-height: 61px;
//                 margin: 0 auto;
//                 border-radius: 5px;
//                 background-color: #153a82;
//                 border-color: #153a82;
//                 cursor: pointer;
//             }
            

//             &.blue {
//                 background-color: #153a82;
//                 color: #ffffff;
                
//                 .header {
//                     color: #ffffff;

//                     .title {
//                         color: #ffffff;
//                     }

//                     .price {
//                         color: #ffffff;
//                     }

//                     .desc {
//                         color: #ffffff;
//                     }
//                 }

//                 .service-list-container {
//                     color: #ffffff;
//                 }

//                 button {
//                     color: #153a82;
//                     background-color: #7bffff;
//                     border-color: #7bffff;
//                 }
//             }
//         }
//     }
// }


// // custom switch
// .pricing-slider {
//     color: #91919186;
//     font-size: 15px;
//     font-family: 'AirbnbCerealApp-Book';
//     padding: 15px 0;

//     label {
//         margin-right: 10px;
//     }
//     .switch {
//         position: relative;
//         display: inline-block;
//         width: 35px;
//         height: 18px;
//     }
        
//     .switch input { 
//         opacity: 0;
//         width: 0;
//         height: 0;
//     }
        
//     .slider {
//         position: absolute;
//         cursor: pointer;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         background-color: #7ed321;
//         -webkit-transition: .4s;
//         transition: .4s;
//         border-radius: 34px;
//     }
        
//     .slider:before {
//         position: absolute;
//         content: "";
//         height: 14.5px;
//         width: 14.5px;
//         left: 3px;
//         top : 2px;
//         background-color: white;
//         -webkit-transition: .4s;
//         transition: .4s;
//         border-radius: 50%;
//         box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.237602), 0px 0px 1px rgba(0, 0, 0, 0.12);
//     }
    
//     input:checked + .slider:before {
//         // -webkit-transform: translateX(20px);
//         // -ms-transform: translateX(20px);
//         transform: translateX(15px);
//     }
// }

// //card enhancement 
// .price-card {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     min-height: fit-content;
//     max-width: 100%;
//     width: 560px;
//     height: 100%;
//     padding: 57px 37px 47px 57px;
//     padding-bottom: 150px;
//     margin-bottom: 30px;
//     margin-top: 20px;
//     color: #ffffff;
//     background: #2E8CFB;
//     background-size: contain;
//     border-radius: 27px;

//     &#single {
//         // max-width: 400px;
//         float: right;
//     }
//     &.extend {
//         // min-height: 570px !important;
//     }
//     .discount-label {
//         font-weight: bold;
//         font-family: 'Nunito';
//         font-size: 15px;
//         line-height: 0px;
//         /* or 220% */
//         display: flex;
//         align-items: center;
//         letter-spacing: 0.376071px;
//         color: #FFFFFF;
//         background: #005FCE;
//         border-radius: 9.72px 9.72px 9.72px 0px;
//         padding: 16px 15px;
//         margin-right: auto;
//         margin-bottom: 6px;

//     }
    
//     .title {
//         font-family: 'Inter';
//         font-style: normal;
//         font-weight: bold;
//         font-size: 23px;
//         line-height: 33px;
//         /* or 272% */
//         display: flex;
//         align-items: center;
//         letter-spacing: -0.308853px;
//         color: #FFFFFF;
//         mix-blend-mode: normal;
//         opacity: 0.87;
//     }
//     .price {
//         font-family: 'Inter';
//         font-style: normal;
//         font-weight: bold;
//         font-size: 38px;
//         line-height: 53px;
//         /* or 155% */
//         letter-spacing: -0.540492px;
//         color: #FFFFFF;
//         span {
//             display: block;
//             font-size: 20.9483px;
//             line-height: 28px;
//             font-weight: 400;
//             /* or 133% */
//             display: flex;
//             align-items: center;
//             letter-spacing: 0.124693px;
//             color: #FFFFFF;
//             mix-blend-mode: normal;
//             opacity: 0.8;
//         }
//     }
//     .price-stroke {
//         font-size: 17px;
//         // line-height: 4px;
//         /* or 141% */
//         font-family: 'Nunito';
//         margin-top: 10px;

//         display: flex;
//         align-items: center;
//         letter-spacing: 0.089286px;

//         color: #FFFFFF;
//         text-decoration: line-through;
//     }
//     .service-list-cont {
//         display: flex;
//         flex-direction: column;
//         margin: 0;
//         margin-top: 30px;
//         padding-left: 20px;

//         .service-list-item {
//             margin: 2px 0;
        
//             &::marker {
//                 font-size: 20px;
//             }
        
//             span {
//                 font-family: 'Inter';
//                 font-style: normal;
//                 font-weight: normal;
//                 font-size: 16px;
//                 line-height: 0px;
//                 letter-spacing: 0.107143px;
//                 color: #FFFFFF;
//                 font-weight: 400;
//                 // margin-left: 16px;
//             }
//         }
//     }
//     #buy-btn {
//         background: #005DAE;
//         border-radius: 60.487px;
//         padding: 20px;
//         margin-right: auto;
//         min-width: 200px;
//         position: absolute;
//         bottom: 0;
//         margin-bottom: 50px;
//         // margin-top: auto;
//     }
//     #link-btn {        
//         margin-right: auto;
//         margin-top: auto;
//     }

// }
// .margin-right {
//     margin-right: auto;
//     margin-left: 0;
// }

// .margin-left {
//     margin-left: auto;
// }

// .margin-auto {
//     max-width: 100%;
//     margin: 0 auto 30px auto;
// }
// .package-title {
//     margin-top: 100px;
//     margin-bottom: 50px;
//     &#center {
//         text-align: center;
//         margin-bottom: 100px;
//         width: auto;
//         margin-left: auto;
//         margin-right: auto;
//     }
//     &#side {
//         max-width: 450px;
//     }
//     #header {
//         font-family: 'Inter';
//         font-style: normal;
//         font-weight: bold;
//         font-size: 40px;
//         line-height: 68px;
//         /* or 170% */
//         letter-spacing: -0.663461px;
//         color: #202020;
//         // max-width: 490px;
//     }
//     #desc {
//         font-family: 'Inter';
//         font-style: normal;
//         font-weight: normal;
//         font-size: 18px;
//         // line-height: 29px;
//         /* or 161% */
//         // display: flex;
//         align-items: center;
//         letter-spacing: 0.107143px;
//         color: #202020;
//         // max-width: 450px;
//     }

// }

// @media (max-width:768px) {
//     .price-card {
//         padding: 30px;
//         padding-bottom: 115px;
//         min-height: unset !important;
//         height: fit-content !important;
//         margin: 0 auto 30px;

//         &#single {
//             // max-width: 400px;
//             float: none;
//             margin: 10px auto;
//         }

//         #buy-btn {
//             margin-bottom: 30px;
//         }
//     }
// }

// @media (max-width:375px) {
//     .price-card {
//         padding: 30px;
//         padding-bottom: 115px;
//         min-height: unset !important;
//         height: fit-content !important;
//         margin: 0 auto 30px;

//         .price {
//             font-family: 'Inter';
//             font-style: normal;
//             font-weight: bold;
//             font-size: 26px !important;
//             line-height: 53px;
//             letter-spacing: -0.540492px;
//             color: #FFFFFF;
//             span {
//                 display: block;
//                 font-size: 20.9483px;
//                 line-height: 28px;
//                 font-weight: 400;
//                 /* or 133% */
//                 display: flex;
//                 align-items: center;
//                 letter-spacing: 0.124693px;
//                 color: #FFFFFF;
//                 mix-blend-mode: normal;
//                 opacity: 0.8;
//             }
//         }

//         &#single {
//             // max-width: 400px;
//             float: none;
//             margin: 10px auto;
//         }

//         #buy-btn {
//             min-width: 160px !important;
//             width: 160px !important;
//         }
//     }
// }

.price-stroke {
    font-size: 1rem;
    text-decoration: line-through;
    color: #6b7280;
}