.notification-page-main-container {
    height: 100%;
    background-color: #f5f6fa;
    
    .notification-page-content-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 6vh 10vw;  

        .head {
            // margin-bottom: 50px;
            margin-bottom: 25px;

            .title {
                font-size: 24px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.83;
                letter-spacing: -0.4px;
                text-align: left;
                color: #3372CA;
            }

            .caption {
                font-size: 18px;
                line-height: 1.53;
                letter-spacing: 0.09px;
                margin-bottom: 15px;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            min-height: 250px;
            height: 100%;
            padding: 10px;
            border-radius: 10px;
            background-color: #ffffff;
            overflow-x: auto;
            margin-bottom: 20px;
        }

        

        .notification-row {
            padding: 1rem 1.5rem;
            margin-bottom: 7.5px;
            font-size: 14px;
            border-radius: 10px;
            cursor: pointer;
            transition: 0.2s ease background-color;

            &:last-child {
                margin-bottom: 0;
            }

            &.unseen {
                background-color: rgba(29, 161, 242, 0.1);
            }

            &:hover {
                background-color: #f5f6fa;
            }

            .badge {
                margin-bottom: 5px;
                font-weight: normal;
                font-size: 12px;
                text-transform: capitalize;
            }

            .date {
                margin-top: 3px;
                font-size: 12px;
                color: #999999;
            }

            id_transaction {
                color: #3372CA;
            }
        }
    }

}

.status-nav-container {
    display: flex;
    margin-bottom: 10px;

    .status-nav {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        padding: 15px 20px;
        cursor: pointer;

        &:hover {
            opacity: 0.65;
        }
    }

    .success {
        color: rgba(126, 211, 33, 0.5);

        &.active {
            border-bottom: solid 2px rgba(126, 211, 33, 0.5);
        }
    }
    .processing {
        color: #fbbc05;

        &.active {
            border-bottom: solid 2px #fbbc05;
        }
    }
    .failed {
        color: rgba(255, 57, 57, 0.5);

        &.active {
            border-bottom: solid 2px rgba(255, 57, 57, 0.5);
        }
    }
}

@media (max-width:767px) {
}