@import "../../styles/colors";

$font-size-input-lg: 16px;
$font-size-label-lg: 13px;
$font-size-input-md: 14px;
$font-size-label-md: 11px;
$font-size-input-sm: 12px;
$font-size-label-sm: 9px;

.console-input-checkbox {
    position: relative;
    // height: 100%;
    margin: 10px 0;
    display: flex;
    align-items: center;
  
    input {
        border-radius: 5px;
        background-color: #f8fafc;
        /* line-height: 1.38; */
        letter-spacing: 0.28px;
        // width: 100%;
        border: solid 1px transparent;
        outline: none;
        font-family: 'AirbnbCerealApp-Medium';
        color: #18181a;
    
        &:focus {
            border: solid 1px $primary-color;
            background-color: rgba(248, 250, 252, 0.21);
        }

        &:disabled {
            // opacity: 0.6;
            background-color: #f5f6fa !important;
        }
    }
  
    label {
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     display: flex;
    //     align-items: center;
        margin: 0;
        margin-left: 5px;
    //     color: #8d8d8d;
    //     transition: all 0.2s ease-out;
    //     pointer-events: none;
    //     border: 1px solid transparent;
    //     transform-origin: 0 0;
    //     cursor: text;
    }
  
    &.error input:focus {
        border: solid 1px #ff3939;
        background-color: rgba(248, 250, 252, 0.21);
    }


    /*** input, label ***/   
    input {
        // height: 55px;
    }
    label {
        font-size: $font-size-input-md;
    }

    &.sm {
        margin: 5px 0; 

        input {
            height: 14px;
            width: 14px;
        }
        label {
            margin-left: 5px;
            font-size: $font-size-input-sm;
        }
    }

    &.md {   
        margin: 10px 0;     

        input {
            height: 16px;
            width: 16px;
        }
        label {
            margin-left: 6px;
            font-size: $font-size-input-md;
        }
    }

    &.lg {     
        margin: 15px 0;
 
        input {
            height: 18px;
            width: 18px;
        }
        label {
            margin-left: 7px;
            font-size: $font-size-input-lg;
        }
    }
}