.credit-schema-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // min-height: 100vh;
    background-color: #E8F0FF;
    padding: 2rem 1.5rem;

    .main-title {
        font-size: 2.5rem;
        font-weight: bold;
        color: #4088EE;
        margin-bottom: 2rem;
        text-align: center;
    }

    .content-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        width: 100%;
        max-width: 1200px;

        @media (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.table-container {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 1.5rem;

    .schema-table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;

        th {
            background-color: #f0f0f0;
            color: #111827;
            padding: 1rem;
            font-size: 0.875rem;
            text-transform: uppercase;
        }

        td {
            padding: 1rem;
            border-bottom: 1px solid #e5e7eb;
            font-size: 1rem;
        }

        tr:hover {
            background-color: #f3f4f6;
        }
    }
}

.illustration-card {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 1.5rem;

    .title {
        font-size: 1.75rem;
        font-weight: bold;
        color: #4088EE;
        text-align: center;
        margin-bottom: 1rem;
    }

    .description {
        font-size: 1rem;
        color: #4b5563;
        margin-bottom: 1rem;
        text-align: justify;
    }

    .formula {
        background-color: #f3f4f6;
        padding: 1rem;
        border-radius: 8px;
        margin-bottom: 1rem;

        pre {
            font-family: 'Courier New', monospace;
            font-size: 0.875rem;
            white-space: pre-wrap;
        }
    }

    .notes {
        font-size: 0.875rem;
        color: #6b7280;

        ul {
            list-style-type: disc;
            padding-left: 1.5rem;

            li {
                margin-bottom: 0.5rem;
            }
        }
    }
}
