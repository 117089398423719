@import "../../styles/colors";

$font-size-input-lg: 16px;
$font-size-label-lg: 13px;
$font-size-input-md: 14px;
$font-size-label-md: 11px;
$font-size-input-sm: 12px;
$font-size-label-sm: 9px;

.console-input {
    position: relative;
    // height: 100%;
    margin: 10px 0;
  
    input {
        border-radius: 5px;
        // background-color: #f8fafc;
        background-color: transparent;
        /* line-height: 1.38; */
        letter-spacing: 0.28px;
        width: 100%;
        border: solid 1px #ced4da;
        outline: none;
        font-family: 'AirbnbCerealApp-Medium';
        color: #18181a;
    
        &:focus {
            border: solid 1px $primary-color;
            background-color: rgba(248, 250, 252, 0.21);
        }

        &:focus + label,
        &:not(:placeholder-shown) + label {
            letter-spacing: 0.23px;
            color: #444444;
            opacity: 0.74;
        }

        &:disabled {
            // opacity: 0.6;
            background-color: #f5f6fa !important;
        }
    }
  
    label {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        margin: 0;
        color: #8d8d8d;
        transition: all 0.2s ease-out;
        pointer-events: none;
        border: 1px solid transparent;
        transform-origin: 0 0;
        cursor: text;
    }
  
    &.error input:focus {
        border: solid 1px #ff3939;
        background-color: rgba(248, 250, 252, 0.21);
    }

    .toggle-password-visibility {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {      
            filter: brightness(0%);
        }
    }


    /*** input, label, toggle-password-visibility ***/   
    input, 
    label,
    .toggle-password-visibility {
        font-size: $font-size-input-md;
        padding: 0 20px;
    }
    input {
        height: 55px;
    }
    input:focus,
    input:not(:placeholder-shown) {
        padding-top: 14px;
        & + label,
        & + label {
            font-size: $font-size-label-md;
            transform: translateY(-10px);
        }
    }

    &.sm {
        margin: 5px 0; 

        input, 
        label,
        .toggle-password-visibility {
            font-size: $font-size-input-sm;
            padding: 0 14px;
        }
        input {
            height: 50px;
        }
        input:focus,
        input:not(:placeholder-shown) {
            padding-top: 13px;
            & + label,
            & + label {
                font-size: $font-size-label-sm;
                transform: translateY(-8px);
            }
        }
    }

    &.md {   
        margin: 10px 0;     

        input, 
        label,
        .toggle-password-visibility {
            font-size: $font-size-input-md;
            padding: 0 20px;
        }
        input {
            height: 55px;
        }
        input:focus,
        input:not(:placeholder-shown) {
            padding-top: 14px;
            & + label,
            & + label {
                font-size: $font-size-label-md;
                transform: translateY(-10px);
            }
        }
    }

    &.lg {     
        margin: 15px 0;
 
        input, 
        label,
        .toggle-password-visibility {
            font-size: $font-size-input-lg;
            padding: 0 26px;
        }
        input {
            height: 65px;
        }
        input:focus,
        input:not(:placeholder-shown) {
            padding-top: 15px;
            & + label,
            & + label {
                font-size: $font-size-label-lg;
                transform: translateY(-12px);
            }
        }
    }
}