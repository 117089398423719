#landing-page-service {
    margin-top: 90px;
    padding: 0 8vw 100px;
    background-color: #fafbfc;
    color: #18181a;
    max-width: 100vw;
    width: 100%;
}

#landingpageservice-content-top {
    padding-top: 80px;
    padding-bottom: 80px;
}

.landing-wrapper {
    max-width: 1300px;
    margin: 0 auto !important;
}

.landing-wrapper-2 {
    max-width: 1000px;
    margin: 0 auto !important;
}

.content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.landing-img-wrapper {
    margin: auto 7vw auto auto;
    display: flex;
    
}
#landing-img-ilustration {
    max-width: 100%;
    width: 125%;
    align-items: center;
    margin-top: -100px;
}
.content-image-wrapper {
    width: 40%;
    align-items: center;

    &.small {
        width: 30%;
    }
    img {
        width: 100%;
    }

    &.mid {
        justify-content: center;
    }

    .convert-btn {
        color: #fff;
        background-color: #2689FF;
        padding: 18px 20px;
        border: none !important;
        border-radius: 5px;  
    }

    .upload-btn {
        color: #2689FF;
        background-color: #fff;
        padding: 18px 20px;
        border: solid 1px #2689FF!important;
        border-radius: 5px;
        margin-left: auto;
        margin-right: 10px;
        display: flex;
        position: relative;            
        #img-download {
            margin: auto 0 auto 15px;
            height: 6px;
            width: auto;
        }
        .download-type-list {
            background: #FFFFFF;
            border-radius: 7px;
            padding: 16px 10px;
            position: absolute;
            width: 100%;
            top: 0;
            margin-left: -20px;
            margin-top: 60px;
            .item-type {
                display: flex;
                color: #2D2D2D;
                margin: 15px;
                align-items: center;
                #dl-icon {
                    height: 17px;
                    width: auto;
                    margin-left: auto;
                }
                span {
                    margin-right: 10px;
                }
                #green {
                    color: #8DC63F;
                }
                #blue {
                    color: #2689FF;
                }
                #red {
                    color: #FF5066;
                }
            }
        }
    }
    .clear-btn {
        color: #757575;
        background-color: #fff;
        padding: 18px 30px;
        border: none !important;
        border-radius: 5px;
        margin-left: auto;
    }
        
    .loading {
        color: #000000;
        font-size: 20px;
        margin: auto; 
        display: flex;
        align-items: center;
        flex-direction: column;
        #i-upload {
            margin-bottom: 20px;
            width: 122px;
        }
        #i-failed {
            margin-bottom: 10px;
            width: 122px;
        }
        .progress-wrap {
            display: flex;
            flex-direction: row;
            vertical-align: middle;
            margin: 20px 0;
            width: 120%;
            .progress {
                width: 100%;
                height: 10px;
                margin: auto 0;
            }
            .label {
                padding: 1px 0 0 0;
                margin-left: 10px;
                font-size: 15px;
            }
        }
        .btn-cancel {
            margin: 20px auto 10px;
            font-size: 16px;
            /* identical to box height, or 106% */
            text-align: center;
            letter-spacing: 0.229048px;
            text-transform: capitalize;
            color: #757575;
            cursor: pointer;
        }
        .btn-cancel-1 {
            margin: 20px auto 10px;
            font-size: 16px;
            padding: 14px 28px;
            /* identical to box height, or 106% */
            text-align: center;
            letter-spacing: 0.229048px;
            text-transform: capitalize;
            color: #fff;
            cursor: pointer;
            background: #2689FF;
            box-shadow: 0px 15px 30px rgba(33, 124, 232, 0.25);
            border-radius: 5px;
        }
        span {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            // line-height: 35px;
            margin-top: 10px;
            /* or 233% */
            text-align: center;
            color: #7B818C;
        }
        .loading-img-wrapper {
            position: relative;
            margin-bottom: 20px;
            #file { 
                width: 122px;
            }
            #reload  {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 33px;
                height: 33px;
                margin-left: 16px;
                margin-bottom: 33px;
                -webkit-animation:spin 4s linear infinite;
                -moz-animation:spin 4s linear infinite;
                animation:spin 4s linear infinite;
            }
            #reload2  {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 33px;
                height: 33px;
                margin-left: 16px;
                margin-bottom: 28px;
                -webkit-animation:spin 3s linear infinite;
                -moz-animation:spin 3s linear infinite;
                animation:spin 3s linear infinite;
            }                       
        }
    }
    #stop {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.229048px;
        text-transform: capitalize;
        color: #F43030 !important;
        cursor: pointer;
    }
    #stt-record,
    #stt-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 !important;
        margin: 0 !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        /* or 162% */
        text-align: center;
        letter-spacing: 0.107143px;

        color: #7B818C;
       
        label {
            color: #3372CA;
            font-weight: 600;
            border-bottom: 2px solid #3372CA;
            cursor: pointer;
        }
    }

    #stt-record {
        margin-bottom: 20px !important;

        .btn-record {
            margin-bottom: 15px;
        }
    }
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

// .on-recording-wrap {
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     span {
//         font-family: 'Inter';
//         font-style: normal;
//         font-weight: 500;
//         font-size: 24px;
//         line-height: 35px;
//         /* identical to box height, or 146% */
//         text-align: center;
//         letter-spacing: 0.107143px;
//         color: #E1E1E1;
//         opacity: 0.9;
//         margin-bottom: 38px;
//     }
//     h5 {
//         font-family: 'Inter';
//         font-style: normal;
//         font-weight: 500;
//         font-size: 49px;
//         line-height: 35px;
//         /* identical to box height, or 71% */

//         text-align: center;
//         letter-spacing: 0.107143px;

//         color: #2B2B2B;

//         opacity: 0.9;
        
//     }
//     #icon {
//         width: 100% !important;
//         margin: 30px auto 20px;
//     }

// }


.on-recording-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    padding: 0 15px;
    margin-top: 15px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.107143px;
    
    span,
    h5,
    p {
        margin: 5px !important;
        // margin-bottom: 5px !important;
        padding: 0 !important;
    }

    span {
        font-size: 14px;
        color: #E1E1E1;
        opacity: 0.9;
    }
    h5 {
        color: #2B2B2B;
        opacity: 0.9;        
    }
    #icon {
        display: none;
    }

    #stop {
        background-color: #F43030 !important;
        color: #ffffff !important;
        padding: 4px 10px !important;
        border-radius: 6px;
    }

}

.convert-btn-wrapper {
    margin-top: auto;
    // margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#input-length {
    margin-right: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #7B818C;
    opacity: 0.5;

}

.content-paragraf {
    max-width: 600px;
    width: 60%;
    margin-top: -40px;
    z-index: 2;

    &.medium {
        max-width: 466px;
    }

    h1 {
        font-weight: bold;
        font-size: 45px;
        line-height: 60px;
        letter-spacing: -1.06154px;
    }
    .span-style {
        background-color: #FCCA17;
        width: 30px;
        height: 30px;
        display: inline-block;
        position: absolute;
        margin-top: 30px;
        z-index: -1;
        margin-left: -15px;
        border-radius: 100px;
    }
    h2 {
        font-weight: bold;
        font-size: 30px;
        line-height: 45px;
        letter-spacing: -0.663461px;
    }
    p {
        font-size: 20px;
        line-height: 35px;
        letter-spacing: 0.136905px;
        padding: 10px 0;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: normal;
        color: #7B818C;
    }

    &.right {
        text-align: right;
    }
}

#landingpageservice-content-partner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    img {
        padding: 15px 10px;
    }
}

#landingpageservice-content-feature {
    width: 100%;
    padding: 100px 0 40px;

    .content-feature-container {
        background-color: #f1f2f3;
        min-height: 454px;
        width: 100%;
        border-radius: 15px;
        padding: 100px;
        margin-bottom: 50px;
    }
}
.btn-landing-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
}
.btn-lihat-detail {
    min-height: 61px;
    background: #2689FF;
    border-radius: 5px;
    font-family: "Nunito";
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 17px;
    min-width: 164px;
    padding: 22px 0 !important;
    margin: 15px;
    border: none !important;    
    box-shadow: 0px 15px 30px rgba(55, 84, 170, 0.29);
    
    &.secondary {
        background: transparent;
        color: #2689FF;
        display: flex;
        align-items: center;
        box-shadow: none;
        // margin-left: 30px;
        padding: 0 20px !important;
        min-width: 164px;
        border: 2px solid #2689FF !important;
        box-shadow: drop-shadow(0px 15px 30px rgba(33, 124, 232, 0.25));
        border-radius: 5px;
        #icon-play {
            height: 35px;
            width: 35px;
            margin:0 10px 0 0;
        }
    }
    &.demo {
        background: transparent;
        color: #2689FF;
        display: flex;
        align-items: center;
        box-shadow: none;
        margin-left: 0px;
        margin-top: 30px;
        padding: 0 0 !important;
        min-width: 164px;
       #icon {
            height: 29px;
            width: 29px;
            margin:0 10px 0 0;
        }
        &:hover {
            background: transparent;
            color: #2689FF;
        }
        &:active {
            background: transparent !important;
            color: #2689FF !important;
            box-shadow: none;
            outline: none;
        }
        &:focus {
            background: transparent;
            color: #2689FF;
            box-shadow: none;
            outline: none;
        }
    }
}

.content-bubble {
    position: absolute;
    left: 0;
}

.content-bubble-2 {
    position: absolute;
    left: 0;
    margin-top: -100px;
    margin-left: -40px;
    img {
        max-height: 70vh;
    }
}

.btn-kontak-kami {
    background-color: #75E8E7 !important;
    color: #27104e !important;
    padding: 22px 27px !important;
    text-transform: capitalize;
    border: none !important;
    width: 155.6px;
    font-weight: bold !important;
    
    &:hover {
        background-color: rgb(93, 187, 211) !important;
        color: #27104e !important;
    }
}

.img-rectangle {
    position: absolute;
    transform: translate(-100px, 45px);
}
// Notulite
#landing-page-notulite-feature {
    padding: 0 8vw 100px;
    background-color: #fafbfc;
    color: #18181a;
    max-width: 100vw;
    width: 100%;
}

// TTS dan STT
#landing-page-tts,
#landing-page-stt {
    margin-top: 90px;
    background-color: #fafbfc;
    color: #18181a;
    max-width: 100vw;
    width: 100%;
}

#landing-page-feature {
    padding: 80px 8vw 100px;
    background-color: #fafbfc;
    color: #18181a;
    max-width: 100vw;
    width: 100%;
}

#content-top-landing-feature {
    text-align: center;
    color: #fff;
    height: 100vh;
    padding-top: 100px;
    background-color: #3372CA;
    margin: 0 auto;
}

.content-landing-feature-paragraf {
    margin: 0 auto;
    max-width: 746px;

    &.large {
        max-width: 978px;
    }

    h1 {
        font-size: 40px;
        font-weight: bold;
        font-size: 40px;
        line-height: 101px;
        letter-spacing: -1.06154px;
        padding: 10px;
    }

    h2 {
        font-weight: bold;
        font-size: 30px;
        line-height: 45px;
        text-align: center;
        letter-spacing: 0.136905px;
        padding: 10px;
    }

    p {
        font-family: 'Nunito';
        font-weight: normal !important;
        font-size: 20px;
        padding: 10px 10px 40px;
    }
}

.btn-landing-blue {
    &.btn-primary {
        background-color: #7bffff;
        color: #3372CA;
        border: none;
        width: 213px;
        height: 61px;
        font-weight: bold;
        box-shadow: 0px 15px 30px rgba(55, 84, 170, 0.29);
    }
    &:active, &:focus, &:hover{
        background-color: transparent !important;
        border: 1px solid #FFFFFF !important;
        opacity: 0.8;
        box-shadow: none !important;
        color: #ffffff;
    }
}

.span-bubble {
    position: absolute;
    transform: translate(-150%, 100%);
}

.content-landingpage-video {
    margin: 0 auto;
    width: 100%;
    max-width: 939px;
    min-height: 200.17px;
    padding: 20px;
    position: relative;
    margin-top: -390px;
    margin-bottom: 100px;

    .video-play-image {
        width: 100%;
        height: auto;
    }

}

.btn-video-play {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    position: absolute;
    background: transparent;
    border: none;
    padding: 0;
    border-radius: 100% !important;
    &:active, &:focus, &:hover{
        border: 1px solid transparent;
        box-shadow: none !important;
        opacity: 0.7;
    }
}

.btn-record {
    position: relative;
    width: 83px;
    height: 83px;
    background-color: #3372CA;
    border-radius: 50% !important;
    border: none;
    transition: 0.3s ease background-color;

    &.recording {
        margin-bottom: 15px;
        animation: pulse 2s infinite;  
        background-color: red;
    }

    img {
        width: 24px !important;
        margin: auto;
    }


    &:hover {
        opacity: 0.9;
    }
}
  
@keyframes pulse {
    0% {
        // background-color: #3372CA;
        opacity: 1;
    }
    50% {
        // background-color: #1f448c;
        opacity: 0.5;
    }
    100% {
        // background-color: #3372CA;
        opacity: 1;
    }
}

.recording-duration {
    position: absolute;
    bottom: -25px;
    left: 20px;
}

#content-service-menu {
    padding: 90px 5vw 0 5vw;
    h2 {
        margin: 0 auto;
        font-size: 30px;
        max-width: 978px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.14px;
        color: #18181a;
        text-align: center;
    }
}

.content-service-menu {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-items: center;
    grid-gap: 10px;
    padding-top: 100px;
    padding-bottom: 167px;
}

.content-service-menu-a {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-items: center;
    grid-gap: 20px;
    padding-top: 100px;
    padding-bottom: 167px;
    justify-content: center;

    display: flex;
    flex-wrap: wrap;
}
.content-service-menu-b {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
    grid-gap: 20px;
    padding-top: 100px;
    padding-bottom: 167px;
    justify-content: center;
    
    display: flex;
    flex-wrap: wrap;
}

.content-service-menu-item {
    max-width: 270px;
    min-height: 320px;
    border-radius: 9px;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 32px 36px;
    transition: all 0.2s;

    h3 {
        font-size: 25px;  
        letter-spacing: 0.93px;
        color: #18181a;
        font-weight: bold;
        padding: 56px 0 12px;
    }
    p {
        font-size: 13px;  
        line-height: 1.54;
        letter-spacing: 0.46px;
        color: rgba(24, 24, 26, 0.7);
        font-family: 'AirbnbCerealApp-Book';
      
    }
    img {
        background-color: #ffffff;
        border-radius: 50%;
    }

    &:hover {
        background-color: #3372CA;
        // margin-top: -20px;
        transform: translateY(-20px);
        cursor: pointer;
        p,h3 {
            color: #fff ;
        }
    }
}

#content-engine {
    height: 100%;
    min-height: 400px;
    background-color: rgba(116, 231, 230, 0.3);
    display: flex;
    align-items: center;
    padding: 45px 96px;
    margin-bottom: 100px;

    .content-paragraf {
        margin-top: 0;
    }

    .content-image-wrapper {
        position: relative;
        max-width: 556px;
        min-width: 280px;
        min-height: 343px;
        height: fit-content;
        border-radius: 9px;
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #2689ff;
        background-color: #ffffff;
        border-style: dashed;
        display: flex;
        flex-direction: column;
        stroke-dashoffset: 62;
        p {
            max-width: 287px;
            font-size: 16px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: 0.11px;
            color: rgba(24, 24, 26, 0.3);
            text-align: center;
            padding-top: 20px;
        }
        img {
            width: 83px;
            height: auto;
        }
    }
}

.content-default {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    #label-top {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 35px;
        /* or 292% */

        letter-spacing: 0.107143px;

        color: #000000;
    }

    .input-text-engine {
        border : none;
        outline: none;
        // min-width: fit-content;
        max-width: 100%;
        min-height: 200px;
        height: 200px;
        // height: fit-content;
        padding: 10px;
        margin-bottom: 10px;
        // text-align: center;
        text-align: left;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.46;
        letter-spacing: 0.11px;
        // color: #3372CA;
        color: #000000;
        background-color: transparent;
        resize: none;
        // overflow: hidden;
        overflow: auto;
        
        &::placeholder {
            color: rgba(24, 24, 26, 0.3);
        }        
    }
}

.content-converted {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #3372CA;
    border-radius: inherit;
    padding: 15%;
    
    &.failed {
        background-color: orange;
    }
    
    .head {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        // justify-content: space-between;
        align-items: flex-end;
        padding: 25px 25px 0;
        // opacity: 0;
        transition: 0.3s ease opacity;
        color: #ffffff;
        font-size: 12px;

        img {
            width: 24px !important;
            margin-right: 18px;
        }

        .close-btn {
            margin-left: auto;
            margin-right: 0;
            cursor: pointer;

            &:hover {
                opacity: 0.9;
            }
        }
    }

    .body {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .transcript-result {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.46;
            letter-spacing: 0.11px;
            color: #ffffff;
        
            .partial {
                opacity: 0.75;
            }
        }
    }

    &:hover {
        .head {
            opacity: 1;
        }
    }
}

.content-landing-package {
    padding: 0 8vw 100px;
    background-color: #f9f9f9;

    #biling-menu-container {
        padding: 5vw 0;
    }

    // .landingpage-content-additional {
    //     margin-top: 80px;
    //     margin-bottom: 50px;
    // }
}

.content-landing-title {
    text-align: center;

    h2 {
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.66px;
        color: #202020;
    }
    p {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: 0.11px;
        color: rgba(24, 24, 26, 0.7);
    }
}

#content-bottom {
    margin-top: 50px;
}

.content-item {
    text-align: left;
    display: flex;
    align-items: center;
    padding-bottom: 7rem;

    &.bg-gray {
        background-color: #eeeeee;
        padding: 95px 8vw 10px;
    }

    &.row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.content-item-wrapper {
    h1 {
        font-size: 40px;
        line-height: 45px;
        font-weight: bold;
        padding-bottom: 43px;
        max-width: 351px;
        line-height: 1.33;
        letter-spacing: -0.53px;
    }
}

.btn-landing {
    font-family: "Nunito";
    font-weight: 700 !important;
    padding: 15px 30px !important;
    height: 61px;
    border-radius: 5px;
    border: none;
    text-transform: capitalize;
    transition: all 0.2s ease-in;
    box-shadow: 0px 15px 30px rgba(55, 84, 170, 0.29);

    &.blue {
        background-color: #2689ff;
    }
}

.content-item-img {
    width: 100%;
}

/* Responsive landing */

@media (max-width:1024px) {
    #landingpageservice-content-partner {
        display: grid;
        grid-template-columns: auto auto auto auto;
    }
    
    #content-engine {
        padding: 45px 3vw 45px 8vw;
    }
    .content-image-wrapper {
        .convert-btn {
            padding: 8px 10px;
            font-size: 15px;            
            &.disabled {
                opacity: 0.7;
            }
        }
        .upload-btn {
            padding: 8px 10px;
            border: solid 1px #2689FF!important;
            // width: 155.6px;
            border-radius: 5px;
            margin-left: auto;
            margin-right: 10px;
            font-size: 15px;
        }
    }
    

}

@media (max-width:768px) {
    .content-wrapper  {
        flex-direction: column;
        &.left {
            flex-direction: column-reverse;
        }
    }
    .content-image-wrapper {
        width: 80%;
        margin-bottom: 50px;
        margin-top: 30px;

        &.small {
            width: 70%;
        }
        .convert-btn {
            padding: 18px 20px;
            font-size: 16px;
            width: -webkit-fill-available;
            &.disabled {
                opacity: 0.7;
            }
        }
        .upload-btn {
            padding: 18px 20px;
            font-size: 16px;
            width: -webkit-fill-available;
        }
    }

    .content-paragraf {
        width: 100%;

        &.right {
            text-align: left;
        }
    }
    
    #landingpageservice-content-feature {
        .content-feature-container {
            padding: 15vw 8vw;
        }
    }
    // .landingpage-content-additional {
    //     padding: 50px 15vw;

    //     img {
    //         transform: translate(-15vw, 105px);
    //     }
    // }
    
    #landingpageservice-content-partner {
        grid-template-columns: auto auto auto;
    }

    .content-service-menu {
        grid-template-columns: auto auto;
    }

    .content-item-wrapper {
        h1 {
            padding-bottom: 5vw;
        }
    }

    .content-item-img {
        padding-top: 10vw;
    }
    .landing-img-wrapper {
        display: flex;
        margin-bottom: 70px;
    }

}

@media (max-width: 600px){
    .content-landing-feature-paragraf {
        h1 {
            font-size: 40px;
            line-height: 50px;
        }
    }
    .content-landingpage-video {
        margin-top: -300px;
    }

    #content-engine {
        padding: 8vw;

        .content-image-wrapper {
            max-width: 100%;
            width: 100%;
        }
    }
    .landing-img-wrapper {
        display: flex;
        margin-bottom: 70px;
    }
}

@media (max-width: 500px){
    #landingpageservice-content-partner {
        grid-template-columns: auto auto;
        justify-content: center;
        img {
            padding: 15px 20px;
        }
    }
    .content-service-menu {
        grid-template-columns: auto;
    }
    .btn-video-play {
        img {
            width: 50px;
        }
    }

    #content-service-menu {
        h2 {
            font-size: 25px;
        }
    }
    .content-image-wrapper {
        .convert-btn {
            padding: 8px 10px;
            font-size: 14px;
        }
        .upload-btn {
            padding: 8px 10px;
            font-size: 14px;
        }
    }    
}

#tts-demo {
    padding: 27px 42px;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }