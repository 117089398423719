#stt-demo {
    padding: 27px 42px;

    &.content-image-wrapper {
        position: relative;
        max-width: 556px;
        min-width: 280px;
        min-height: 343px;
        height: fit-content;
        border-radius: 9px;
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #2689ff;
        background-color: #ffffff;
        border-style: dashed;
        display: flex;
        flex-direction: column;
        stroke-dashoffset: 62;

        p {
            max-width: 287px;
            font-size: 16px !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: 0.11px;
            color: rgba(24, 24, 26, 0.3);
            text-align: center;
            padding-top: 20px;
        }

        img {
            width: 83px;
            height: auto;
        }
    }

    .btn-record {
        width: 83px !important;
        height: 83px !important;
        margin: unset;
    }

    .dropdown-container {
        text-align: left;
        border: 1px solid #ccc;
        position: relative;
        border-radius: 5px;
        margin: 10px;
    }

    .dropdown-input {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
    }

    .dropdown_lang {
        border-radius: 20px !important;
        padding-left: 10px;
        width: 180px;
    }

    .dropdown_lang:hover {
        border-color: #39f;
        -webkit-box-shadow: 0px 0px 6px 0px #7cb7ff;
        -moz-box-shadow: 0px 0px 6px 0px #7cb7ff;
        box-shadow: 0px 0px 6px 0px #7cb7ff;
    }

    .dropdown-menu-language {
        position: absolute;
        z-index: 1;
        transform: translateY(4px);
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        overflow-y: auto;
        max-height: 150px;
        background-color: #fff;
    }

    .dropdown-item {
        padding: 5px;
        cursor: pointer;
    }

    .dropdown-item:hover {
        background-color: #9fc3f870;
    }

    .upload-btn-container {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // height: 100%;
        // margin-bottom: 50px;
        // font-size: 14px;
    
        .function-btn {
          padding: 10px 12px;
          background-color: #0d6efd;
          color: #ffffff;
          font-size: 14px;
          border-radius: 8px;
          width: 120px;
          // padding-top: 2px;
    
          &:disabled {
            opacity: 0.8;
            background-color:  #cdcdcd;
            pointer-events: none;
          }
    
        }
    
        .function-btn:hover {
          -webkit-box-shadow:0px 0px 5px 2px #7cb7ff !important;
          -moz-box-shadow: 0px 0px 5px 2px #7cb7ff !important;
          box-shadow: 0px 0px 5px 2px #7cb7ff !important;
        }
    
        .upload-btn {
          padding: 10px 12px;
          background-color: #0d6efd;
          color: #ffffff;
          font-size: 14px;
          border-radius: 8px;
          // padding-top: 2px;
    
          &:disabled {
            opacity: 0.8;
            background-color:  #cdcdcd;
            pointer-events: none;
          }
        }
    
        .upload-btn:hover {
          -webkit-box-shadow:0px 0px 5px 2px #7cb7ff !important;
          -moz-box-shadow: 0px 0px 5px 2px #7cb7ff !important;
          box-shadow: 0px 0px 5px 2px #7cb7ff !important;
        }
    
        .upload-btn-file {
          // padding: 10px 12px;
          height: 70px;
          width: 70px;
          background-color: #2689ff;
          color: #ffffff;
          font-size: 16px;
          border-radius: 50%;
    
          // &:disabled {
          //   opacity: 0.8;
          //   pointer-events: none;
          // }
        }
        
        .upload-btn-file:hover {
          background-color: #1760ba;
          -webkit-box-shadow:0px 0px 10px 0px #7cb7ff !important;
          -moz-box-shadow: 0px 0px 10px 0px #7cb7ff !important;
          box-shadow: 0px 0px 10px 0px #7cb7ff !important;
        }
    
        .stop-btn {
          // padding: 10px 12px;
          height: 70px;
          width: 70px;
          background-color: red;
          color: #ffffff;
          font-size: 16px;
          border-radius: 50%;
          animation: zoom-in-zoom-out 2s ease infinite;
    
          // &:disabled {
          //   opacity: 0.8;
          //   pointer-events: none;
          // }
        }
        
        .stop-btn:hover {
          // background-color: #1760ba;
          -webkit-box-shadow:0px 0px 10px 0px #ff7c7c !important;
          -moz-box-shadow: 0px 0px 10px 0px #ff7c7c !important;
          box-shadow: 0px 0px 10px 0px #ff7c7c !important;
        }
    
        @keyframes zoom-in-zoom-out {
          0% {
            transform: scale(1, 1);
          }
          50% {
            transform: scale(0.8, 0.8);
          }
          100% {
            transform: scale(1, 1);
          }
        }
      }  
}

@media (max-width:375px) {
  #stt-demo {
      &.content-image-wrapper {
          min-width: 220px !important;
      }
  }
}