.ncloud-pagination {
    width: fit-content;
    margin: 0 auto;
    border-radius: 17.6px !important;
    background-color: #fbfbfb;
    padding: 7px !important;

    .page-item {
        .page-link {
            min-width: 21px;
            min-height: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: 0 3px;
            font-size: 13px;
            color: #3372CA;
            background-color: transparent !important;
            border: none !important;
            border-radius: 100%;
        }

        &.active {
            .page-link {
                font-weight: bold;
                color: #fff;
                background-color: #3372CA !important;
            }
        }

        &.disabled {
            .page-link {
                opacity: 0.65;
            }
        }
    }
}

// // @import "../../styles/colors.scss";

// #paging-box {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     // margin-left: auto;
// }

// .pagination {
// 	margin-bottom: 0 !important;
// }

// .pagination > li > a {
//     background: #ffffff !important;
// }

// .pagination > li.page-item.disabled > a {
//     background: #fafafa !important;
// }

// .pagination .page-item .page-link {
// 	color: #000000 !important;
// 	z-index: 0;
// }

// .pagination .page-item.disabled .page-link {
// 	color: #afafaf !important;
// }

// .pagination .page-link {
//     box-shadow: none !important;
//     font-size: 12px;
//     font-weight: 500;
// }

// .page-item .page-link:hover {
// 	// color: $btn-primary-color !important;
// }

// .page-item.active .page-link {
// 	color: #fff !important;
// 	// background-color: $btn-primary-color !important;
// 	// border-color: $btn-primary-color !important;
// }

// .gotopage-arrow {
//     margin: auto 10px;
//     background-color: unset;
//     box-shadow: unset;
//     padding: 0;
//     // color: $primary-text-color !important;
// }

// .gotopage-arrow i {
//     font-size: 25px !important;
//     padding: 0;
//     vertical-align: middle;
// }

// .gotopage-input {
//     min-width: unset;    
//     width: 60px;
//     height: 35px;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     white-space: nowrap;
//     // color: $primary-text-color;
//     font-size: 16px;
//     font-weight: bold;
//     padding-left: 13px;
//     text-align: center;
//     box-shadow: 3px 3px 16px 0 rgba(52, 50, 101, 0.25) !important;
//     border: none !important;
// }

// .gotopage-button {
//     min-width: unset;
//     width: 60px;
//     height: 40px;
//     margin-left: 5px;
//     padding: 0;
// }

// @media screen and (max-width: 767px) {
//     #paging-box {
//         margin-left: unset;
//         justify-content: center;
//     }

//     .gotopage-arrow i {
//         font-size: 25px !important;
//         padding: 0;
//         vertical-align: middle;
//     }
    
//     .gotopage-input {
//         font-size: 0.8rem;
//         width: 65px;
//         height: 40px;
//         font-size: 0.8rem;
//         width: 60px;
//     }

//     .gotopage-button {
//         height: 40px;
//         width: 50px;
//         padding: 0.05rem 0.3rem !important;
//         margin-top: 0.05px !important;
//     }

//     .pagination-sm .page-link {
//         margin: 0.1rem !important;
//         padding: 0.25rem 0.6rem !important;
//         border-radius: 0.25rem !important;
//     }
// }