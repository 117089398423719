.trx-history-page-main-container {
    height: 100%;
    background-color: #f5f6fa;

    table {
        text-transform: capitalize;
    }
    
    .trx-history-page-content-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 6vh 10vw;  

        .head {
            // margin-bottom: 50px;
            margin-bottom: 25px;

            .title {
                font-size: 24px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.83;
                letter-spacing: -0.4px;
                text-align: left;
                color: #3372CA;
            }

            .caption {
                font-size: 18px;
                line-height: 1.53;
                letter-spacing: 0.09px;
                margin-bottom: 15px;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            min-height: 250px;
            height: 100%;
            border-radius: 25px;
            background-color: #ffffff;
            padding: 30px;
            overflow-x: auto;
            margin-bottom: 20px;
        }
    }

    // @media (max-width:767px) {
    //     table {
    //         display: flex;
    //         width: 100%;

    //         thead {
    //             display: none;
    //         }

    //         tr {
    //             display: flex;
    //             flex-direction: column;
    //             border-bottom: 1px solid #f5f6fa;

    //             td {
    //                 padding: 5px 10px;
    //             }
    //         }
    //     }
    // }
    .refresh-btn {
        // padding: 10px 12px;
        height: 30px;
        width: 30px;
        background-color: #2689ff;
        color: #ffffff;
        font-size: 8px;
        border-radius: 50%;
        
        // &:disabled {
        //   opacity: 0.8;
        //   pointer-events: none;
        // }
      }
      
      .refresh-btn:hover {
        // background-color: #1760ba;
        -webkit-box-shadow:0px 0px 10px 0px #ff7c7c !important;
        -moz-box-shadow: 0px 0px 10px 0px #ff7c7c !important;
        box-shadow: 0px 0px 10px 0px #ff7c7c !important;
      }
}

.status-nav-container {
    display: flex;
    margin-bottom: 10px;

    .status-nav {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        padding: 15px 20px;
        cursor: pointer;

        &:hover {
            opacity: 0.65;
        }
    }

    .success {
        color: rgba(126, 211, 33, 0.5);

        &.active {
            border-bottom: solid 2px rgba(126, 211, 33, 0.5);
        }
    }
    .processing {
        color: #fbbc05;

        &.active {
            border-bottom: solid 2px #fbbc05;
        }
    }
    .failed {
        color: rgba(255, 57, 57, 0.5);

        &.active {
            border-bottom: solid 2px rgba(255, 57, 57, 0.5);
        }
    }
}

@media (max-width:767px) {
}