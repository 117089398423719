.modal-transaction-detail {
    text-transform: capitalize;
    
    .modal-dialog {
        max-width: 535px;
    }

    .modal-content,
    .modal-header,
    .modal-body,
    .modal-footer {
        border-radius: 0;
        border: none;
        background-color: #fbfbfb;
    }   

    .modal-header {
        padding: 50px 50px 0;

        .title {
            // display: flex;
            // max-width: 100px;
            // margin: 0 auto 10px;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 0.1px;
            text-align: center;
            color: #18181a;
            // margin-bottom: 40px;
            margin: 0 auto;
            // margin-top: 25px;
        }
    }   

    .close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
    //     margin-left: auto;
    //     margin-right: -35px;
    //     cursor: pointer;
    //     padding: 5px;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        min-height: 450px;
        padding: 0 50px 50px;

        .content {
            margin-top: 40px;
            
            .sub-title {
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.17;
                letter-spacing: 0.1px;
                text-align: left;
                color: #747474;
                background-color: #f2f2f2;
                margin: 20px -50px;
                padding: 0 50px;
            }

            .sub-content {
                // display: flex;
                // justify-content: space-between;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                letter-spacing: 0.1px;
                text-align: left;
                color: #747474;

                .row {
                    align-items: center;
                    padding: 1.5px 0;
                    margin: 0;

                    .left {
                        width: 50%;
                    }

                    .right {
                        color: #18181a;
                    }
                }
            }
        }
    }

    .btn-select-payment-method {
        margin: auto;
    }

    .btn-view-payment-method {
        margin: auto;
        width: 100%;
        padding: 10px;
        margin-top: 10px;
    }
}