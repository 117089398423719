.media-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.media-enabled {
    pointer-events: auto;
    opacity: 1.0;
}

#audio-player-container {
    width: 100%;
    margin-top: 7px;    
    margin-bottom: 3px;
    // margin-right: 15px;
    // margin-left: -10px;
    // background: #E9E9E9;
    // border-radius: 39px;
    // padding-right: 15px;


    #audio-player {
    }

    #audio-rewind, 
    #rate-info, 
    #audio-forward, 
    #audio-replay-5, 
    #audio-forward-5, 
    #audio-repeat {
      color: #2689ff;
      cursor: pointer;
    }
    
    #audio-rewind:hover, 
    #audio-forward:hover, 
    #audio-replay-5:hover, 
    #audio-forward-5:hover, 
    #audio-repeat:hover {
      color: #0E4F72;
    }
    
    #audio-play {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        z-index: 1;
        width: 40px;
        height: 40px;
        // box-shadow: 0 2px 7px 3px rgba(0, 0, 0, 0.02);
        // background-color: #ffffff;
        border-radius: 50%;

        i {
            font-size: 14px;
        }
    }
    
    #audio-play-icon {
        font-size: 2rem;
        margin: 0;
        padding: 0;
        color: #2689ff;
        cursor: pointer;
        height: 12px;
        width: auto;
    }
    
    .audio-player-slider{
        display: flex;
        align-items: center;
        height: 40px;
        // padding-left: calc(40px + 5%);
        padding-left: 50px;
        padding-right: 10px;
    }
    
    .dynamic-slider-thumb {
        margin-top: -2.5px !important;
        opacity: 0;
        transition: 0.1s ease opacity;
    }
    
    .dynamic-slider-thumb div {
        height: 10px !important;
        width: 10px !important;
    }
    
    .audio-player-line {
        height: 5px !important;
        background-color: #AFAFAF !important;
        border-radius: 4.3px;
    }

    .dynamic-slider-track {
        background-color: #2689ff;
        border-radius: 4.3px;
    }
    
    .audio-player-tools-container {
        position: absolute;
        top: 0.7em;
        right: 0;
        display: flex;
        padding-right:0.3em;
        padding-bottom:0.6em;
        align-content:space-between;
        align-items: center;
        justify-content: center;
    }
    
    .audio-player-tools-container .material-icons {
        font-size: 1.5rem !important;
    }
    
    .audio-bar {
        display: flex;
        padding-right: 0.3em;
        box-sizing: border-box;
    }

    &:hover {
        .dynamic-slider-thumb {
            opacity: 1;
        }
    }
}

// @media (max-width: 767px) {
//     #audio-player-container {
//         position: fixed;
//         top: unset;
//         bottom: 0;
//         left: 0;
//         z-index: 1031;
//         margin: 0;
//         padding: 0;
//         height: 50px;
    
//         #audio-player {
//             height: 100%;
//             background-color: #fff;
//             border: none;    
//             border-radius: 0;
//             box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
//             padding: 9px 12px;
    
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
    
//             #audio-current-time {
//                 // width: 25%;
//                 width: 60px;
//                 text-align: left;
//                 color: #00000080;
//                 font-size: 11px;
//             }

//             #audio-end-time {
//                 // width: 25%;
//                 width: 60px;
//                 text-align: right;
//                 color: #00000080;
//                 font-size: 11px;
//             }

//             #audio-player-buttons {
//                 display: flex;
//                 align-items: center;
//                 // width: 100%;
//                 text-align: center;

//                 a:hover {
//                     color: #2689ff !important;
//                 }

//                 #audio-stop {

//                     #audio-stop-icon {
//                         font-size: 1.75rem;
//                     }
//                 }
                
//                 #audio-play {
//                     position: unset;
//                     /* height: 100%; */
//                     /* height: fit-content; */
//                     margin: 0 10px;

//                     #audio-play-icon {
//                         color: unset;
//                     }
//                 }
                
//                 #audio-repeat {
//                     margin: 0;
//                     padding: 0;
//                     font-size: 1rem;

//                     #audio-repeat-icon {
//                         color: unset;
//                     }
//                 }

//                 #audio-seek-backward,
//                 #audio-seek-forward {
//                     padding: 0 15px;
//                 }
//             }
//         }
//     }

//     .BKAudioPlayerContainer {
//         position: fixed;
//         width: 100%;
//         top: 6.25rem;
//         left: 0px;
//         background-color: rgb(255, 255, 255);
//         padding: 0.65rem 0.5rem 0px;
//         box-shadow: 0 1px 3px 0 rgba(0,0,0,.05);
//         z-index: 1;
//         height: 5.55rem;
//         transition: top 0.3s;
//     }
// }



