.head-menu {
  padding: 0.5rem 1rem !important;
  background-color: #ffffff !important;

    
  // .logo-header {
  //   position: relative;
  //   width: 100%;
  //   margin-bottom: 5vh;
  //   padding-bottom: 5vh;
  //   border-bottom: 1px solid #3372CA;

  //   img {
  //     width: 100%;
  //   }

  //   div {
  //     font-family: 'AirbnbCerealApp-Bold';
  //     position: absolute;
  //     top: -15px;
  //     left: 43px;
  //     // right: 0px;
  //     // margin-top: -9px;
  //   }
  // }

  .add-service-btn {
    width: 100%;
    margin-bottom: 5vh;
    // left: 25px;
    
    button {
      min-width: 164px;
      width: 100%;
      height: 61px;
      border-radius: 53.5px;
      background-color: #3372CA;
      border: none;
      outline: none;
      text-decoration: none;
      display: flex;
      vertical-align: middle;
      justify-content: center;

      p {
        color: #ffffff;
        font-family: 'AirbnbCerealApp-Bold';
        font-size: 13px;
        line-height: 1.31;
        letter-spacing: 0.23px;
        margin: auto 0;
        padding-right: 19px;
        text-decoration: none;
        border: none;
        outline: none;        
      }

      img {
        width: 31px;
        height: 31px;
        background-color: #ffffff;
        border-radius: 50%;
        margin: auto 0;
        padding: 3px;
      }
  
    }
  }

  .menu {
    width: 100%;
    margin: 20px 0;
    // margin-bottom: 15vh;

    button {
      border: none;
      outline: none;
      text-decoration: none;
      display: flex;
      vertical-align: middle;
      justify-content: center;
      padding: 7px 0;
      background-color: transparent;
    }

    a {
      font-family: 'AirbnbCerealApp-Medium';
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      // word-spacing: 10px;
      word-spacing: 3px;
      color: rgba(24, 24, 26, 0.4);
      margin: 0;
      text-decoration: none !important;

      &.active {
        color: #3372CA;
      }

      &:hover {
        color: #3372CA;
      }
    }
  }

  .accordion {
    // display: block;
    position: relative;

    .arrow-icon {
      height: 6px;
      // transition: 0.3 ease all;
    }

    .card-active {
      .arrow-icon {
        transform: rotate(180deg);
      }
    }

    .card,
    .card-header,
    .card-body {
      width: 100%;
      background-color: transparent;
      border: none;
      padding: 0;

      button {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        a {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: left;
        }
      }
    }

    .card-body {
      padding-left: 15px;
    }
  }

      .account {
        margin-top: auto;
        // padding-top: 5vh;
        // padding-bottom: 5vh;
        padding: 20px 0;
        border-top: 1px solid #3372CA;

        .dropdown-toggle::after {
          display: none;
        }
        
        .dropdown-menu {
          font-size: 14px;
          margin-bottom: 5px;
        }

        #profile-btn {
          max-width: 100%;
          border: none;
          outline: none;
          text-decoration: none;
          display: flex;
          vertical-align: middle;
          align-items: center;
          // justify-content: center;
          padding: 0;
          background-color: transparent;

          img {
            width: 32px;
            height: 32px;
          }

          .profile-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            font-family: 'AirbnbCerealApp-Medium';
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.8px;
            color: #3372CA;
            margin: auto 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            .username {
              max-width: 100%;
              font-size: 14px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .email {
              max-width: 100%;
              font-size: 11px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
}

// @media (max-width:767px) {
//   .side-menu {
//     // position: fixed;
//     // z-index: 1030;
//     // top: 0;
//     // left: 0;
//     // width: 100%;

//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     height: 80px;
//     padding: 0 8vw;
//     background-color: #fff;
//     max-width: 100%;
//     width: 100%;
//     position: fixed;
//     top: 0;
//     box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
//     z-index: 10;
//   }
// }