#notification-bell-container {
    position: fixed;
    z-index: 1;
    top: 20px;
    right: 20px;
    
    
    #notification-bell-icon-container {
        padding: 5px;
        background-color: #ffffff;
        border-radius: 50%;
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;

        &.active {
            background-color: rgba(29, 161, 242, 0.1);
        }

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #ffffff;
            border-radius: 50%;
        }
    }

    #notification-popover {
        display: flex;
        flex-direction: column;
        max-width: calc(100vw - 40px);
        max-height: calc(100vh - 40px);
        width: 300px;
        height: 400px;
        border-color: #ebebeb;

        .arrow {
            &::before {
                border-bottom-color: #ebebeb;
            }
        }
        .popover-header {
            background-color: #ffffff;
            border: none;
        }

        .popover-body {
            height: 100%;
            padding: 7.5px;
            border: none;
            overflow-x: auto;
        }

        .popover-footer {
            padding: .5rem .75rem;
            text-align: center;
            border: none;
        }

        .notification-row {
            padding: 0.5rem 0.75rem;
            margin-bottom: 7.5px;
            border-radius: 10px;
            cursor: pointer;
            transition: 0.2s ease background-color;

            &:last-child {
                margin-bottom: 0;
            }

            &.unseen {
                background-color: rgba(29, 161, 242, 0.1);
            }

            &:hover {
                background-color: #f5f6fa;
            }

            .badge {
                margin-bottom: 5px;
                font-weight: normal;
                font-size: 12px;
                text-transform: capitalize;
            }

            id_transaction {
                color: #3372CA;
            }
        }
    }
}

.mobile {
    #notification-bell-container {
        top: 82px;
    } 
}