// #navbar-service {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     height: 90px;
//     padding: 0 8vw;
//     background-color: #fff;
//     max-width: 1300px;
//     width: 100%;
//     position: fixed;
//     top: 0;
//     box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
//     z-index: 10;
//     #responsive-navbar-nav {
//         justify-content: space-between;
//         .span {
//             width: 110px;
//         }
//     }
// }

#navbar-service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    padding: 0 8vw;
    background-color: #fff;
    max-width: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    z-index: 10;
    #responsive-navbar-nav {
        justify-content: space-between;
        .span {
            width: 110px;
        }
    }
}

#navbar-feature {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    padding: 0 8vw;
    background: #3372CA;
    max-width: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
    #responsive-navbar-nav {
        justify-content: space-between;
        .span {
            width: 110px;
        }
    }
}

#navbar-wrapper {
    max-width: 1300px;
    width: 100%;
    margin: auto;    
}

#navbarlandingpageservice-kontakkami {
    // color: #2689ff;
    // font-family: "Nunito";
    // font-size: 16px;
    line-height: 17px;
    &:hover {
        text-decoration: none;
    }
}

// #navbar-feature {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     position: fixed;
//     max-width: 100%;
//     width: 100%;
//     height: 90px;
//     left: 0px;
//     top: 0px;
//     background: #3372CA;
//     padding: 20px 8vw;
//     z-index: 10;
//     #responsive-navbar-nav {
//         justify-content: space-between;
//         .span {
//             width: 110px;
//         }
//     }
// }

.btn-navbar-kontak-kami-landing {
    font-weight: 700;    
    padding: 10px 15px;
    font-family: 'Nunito';
    border-radius: 0.25rem;
    
    &.transparant {
        background-color: transparent;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */
        text-transform: capitalize;
        color: #18181A;
    }
    &.blue {
        font-size: 14px;
        background-color: #2689ff;
        margin-left: 15px;
        padding: 10px 20px;
        color: #ffffff;        
        border: 1px solid #2689ff;
    }
    &.second {
        font-size: 14px;
        background-color: #ffffff;
        margin-left: 15px;
        padding: 10px 35px;
        color: #2689ff;
        border: 1px solid #2689ff;
    }
}

.btn-navbar-kontak-kami {
    font-weight: normal;    
    padding: 10px 15px;
    font-family: 'Nunito';
    border-radius: 0.25rem;
    
    &.transparant {
        background-color: transparent;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */
        text-transform: capitalize;
        color: #ffffff;
    }
    &.blue {
        font-size: 14px;
        background-color:#75E8E7;
        margin-left: 15px;
        padding: 10px 20px;
        color: #3372CA;
        border: 1px solid #75E8E7 !important;
    }
    &.sub {
        margin-left: 15px;
        padding: 10px 20px;
        font-size: 14px;
        color: #FFFFFF;
        border: 1px solid #FFFFFF !important;
        background-color: transparent !important;
    }
    &:active, &:focus, &:hover{
        color: #FFFFFF;
        border: 1px solid #FFFFFF !important;
        background-color: transparent !important;
        opacity: 0.8;
        box-shadow: none !important;
    }
}

.navbar-service-nav {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    text-transform: capitalize;
    color: #18181A;
    padding: 10px 15px;
    &.active {
        color: #2689ff;
    }
    &:hover {
        text-decoration: none;        
        color: #18181A;
    }
}

.navbar-feature-nav {
    color: #fff;
    font-family: 'AirbnbCerealApp-Light';
    font-size: 16px;
    padding: 10px 15px;
    &.active {
        color: #75E8E7;
    }
    &:hover {
        text-decoration: none;
    }
}

@media (min-width:768px) and (max-width: 825px) {
    .navbar-feature-nav {
        padding: 10px;
    }
    .navbar-service-nav {
        padding: 10px;
    }
    .navbar {
        padding: 0 !important;
    }
}

@media (max-width: 767px) {
    .navbar {
        padding: 0;
    }
    #navbar-feature {
        padding: 20px 0 !important;
        .navbar-toggler{
            margin-right: 5vw;
        }
        .navbar-brand {
            padding-left: 5vw;
        }
        .navbar-collapse {
            margin-top: 19px;
            width: 100vw;
            background-color: rgba(24, 24, 26, 0.9);
            .navbar-nav {
                padding: 10px 0;
            }
            a {
                // margin-bottom: 10px;
                padding-left: 8vw;
            }
            button {
                margin: 10px auto;
                // margin-left: 5vw;
            }
        }
    }

    #navbar-service {
        padding: 20px 0 !important;
        .navbar-toggler{
            margin-right: 5vw;
        }
        .navbar-brand {
            padding-left: 5vw;
        }
        .navbar-collapse {
            margin-top: 19px;
            width: 100vw;
            background-color: #ffffff;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
            .navbar-nav {
                padding: 10px 0;
            }
            a {
                // margin-bottom: 10px;
                padding-left: 8vw;
            }
            button {
                // margin-bottom: 25px;
                margin: 10px auto;
                // margin-left: 5vw;
            }
        }
    }

    
}