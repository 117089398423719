
.custom-card {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 25.6px;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    cursor: pointer;  
    transform: translateY(0px);
    transition: 0.3s ease transform;  

    &:hover {
        transform: translateY(-10px);
    }
}

.dashboard-card {
    width: 100%;
    // min-height: 57vh;
    // margin-bottom: 20px;
    padding-top: 150%; /* 100:1127 Aspect Ratio */
    position: relative; /* If you want text inside of it */

    .card-status {
        position: absolute;
        top: 7%;
        right: 10%;
        // max-width: 6.5vw;
        width: 40%;
        padding: 0 1.5vw;
        height: 25px;
        border-radius: 5px;
        // background-color: rgba(126, 211, 33, 0.1);
        // color: #c5f3a1;
        font-family: 'AirbnbCerealApp-Bold';
        font-size: 12px;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        margin: 0 -0.5vw 10px auto;

        &.active {
            background-color: rgba(126, 211, 33, 0.1);
            color: #c5f3a1;
        }

        &.pending {
            background-color: rgba(253, 173, 0, 0.1);
            color: #f9cca6;
        }
    }
    .card-top-1 {
        position: absolute;
        top: 15%;
        left: 10%;
        font-family: 'AirbnbCerealApp-Bold';
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.63;
        letter-spacing: -0.2px;
        color: rgba(24, 24, 26, 0.7);
    }

    .card-top {
        position: absolute;
        top: 20%;
        left: 10%;
        font-family: 'AirbnbCerealApp-Bold';
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.63;
        letter-spacing: -0.2px;
        color: rgba(24, 24, 26, 0.3);
    }

    .card-body {
        position: absolute;
        top: 30%;
        left: 10%;
        width: 100%;
        padding: 0;

        h3 {
            width: 80%;
            font-family: 'AirbnbCerealApp-Bold';
            font-size: 26px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.93px;
            color: #3372CA;
            // max-width: 215px;
            // width: 15vw;
        }

        p {
            // position: absolute;
            // top: 35%;
            // left: 10%;
            width: 70%;
            // max-width: 215px;
            font-family: 'AirbnbCerealApp-Book';
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            // line-height: 1.54;
            padding-top: 1.5vh;
            letter-spacing: 0.46px;
            color: rgba(24, 24, 26, 0.7);
        }       
        
        button {
            position: relative;
            margin-left: -10%;
            padding-top: 15%;
            height: 61px;
            font-family: 'AirbnbCerealApp-Medium';
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.23px;
            border: none;
            outline: none;
            text-decoration: none;
            // margin: auto auto;
            width: 100%;
            // max-width: 220px;
            border-radius: 53.5px;
            color: #2689ff;
            background-color: transparent;
        }

    }
    button {
        position: absolute;
        bottom: 0;
        padding: 0 0 10px 0;
        // position: relative;
        // margin-left: -10%;
        // padding-top: 15%;
        height: 61px;
        font-family: 'AirbnbCerealApp-Medium';
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.23px;
        border: none;
        outline: none;
        text-decoration: none;
        // margin: auto auto;
        width: 100%;
        // max-width: 220px;
        border-radius: 53.5px;
        color: #2689ff;
        background-color: transparent;
    }    
}

.services-card {
    min-height: unset;
    height: 100%;
    padding: 20px 30px !important;
    position: relative;

    .card-top {
        font-family: 'AirbnbCerealApp-Bold';
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.63;
        letter-spacing: -0.2px;
        color: rgba(24, 24, 26, 0.3);
    }

    h3 {
        font-family: 'AirbnbCerealApp-Bold';
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.93px;
        color: #3372CA;
        // max-width: 215px;
        // width: 15vw;
        margin-bottom: 0.5rem;
    }

    p {
        // max-width: 215px;
        font-family: 'AirbnbCerealApp-Book';
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        // line-height: 1.54;
        // padding-top: 1.6vh;
        letter-spacing: 0.46px;
        color: rgba(24, 24, 26, 0.7);
        margin-bottom: 0.5rem;
    }

    button {
        height: 61px;
        font-family: 'AirbnbCerealApp-Medium';
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.23px;
        border: none;
        outline: none;
        text-decoration: none;
        margin: auto 0 20px 0;        
        max-width: 209px;
        border-radius: 5px;
        color: #ffffff;
        background-color: #3372CA;

    }
}