@import "../../styles/colors";

/* Layout */
/* onboard */
.onboard-container {
  display: flex;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  
  .btn-primary {
    color: #ffffff !important;
    background-color: #3372CA !important;
    border: 2px solid #3372CA !important;
    border-color: #3372CA !important;
  }
  
  .btn-secondary {
    color: #3372CA !important;
    background-color: transparent !important;
    border: 2px solid #3372CA !important;
    border-color: #3372CA !important;
  }
}

.onboard-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // padding: 20px !important;
  padding: 2% 4% !important;

  @media (max-width:575px) {
    padding: 20px 8vw !important;
  }
}

.onboard-information {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // padding: 20px !important;
  padding: 2% 310px !important;

  @media (max-width:575px) {
    padding: 20px 8vw !important;
  }
}

.onboard-form {
  background-color: #ffffff;
  width: 40%;
  padding-top: 20px;
  z-index: 1;
}
  
.onboard-slider {
  background-color: $primary-color;
  width: 60%;
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'AirbnbCerealApp-Bold';
  font-size: 34px;
  line-height: 1.47;
  color: #ffffff;

  p {
    z-index: 1;
  }

  @media(max-width: 575px) {
    display: none;
  }
}
  
.onboard-header {
  font-family: 'AirbnbCerealApp-Bold';
  font-size: 40px;
  line-height: 1.33;
  letter-spacing: 0.7px;
  text-align: center;
  color: #18181a;
  width: 100%;
  margin-bottom: 17px; 
}

.onboard-header .text-style-1 {
  color: $primary-color;
}
  
.onboard-img-sent {
  width: 150px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;
}

.onboard-header-span {
  opacity: 0.71;
  font-family: 'AirbnbCerealApp-Book';
  font-size: 16px;
  line-height: 1.69;
  letter-spacing: 0.28px;
  text-align: center;
  color: #444444;
  text-align: center;
  margin-bottom: 20px;
}

.onboard-span {
  width: 100%;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: 0.23px;
  color: #444444;
  margin: 28px auto;
  text-align: center;
}

.onboard-span a {
//   color: $primary-color;
  text-decoration: none;
}
  
.onboard-span a:hover,:active {
  text-decoration: none;
}

.onboard-span-tns {
  // opacity: 0.5;
  font-family: 'AirbnbCerealApp-Book';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0.23px;
  text-align: center;
  color: #444444;
  margin: 20px auto;
  max-width: 272px;
}

.onboard-span-tns a {
//   color: $primary-color;
  text-decoration: none;
}

.onboard-span-tns a:hover,:active {
  text-decoration: none;
}

.error-alert {
  font-family: 'AirbnbCerealApp-Book';
  font-size: 14px;
  letter-spacing: 0.25px;
  color: #ff3939;
  text-align: center;
  padding-bottom: 16px;
}

.error-alert img {
  margin-right: 5px;
}

/* slider */
.slider-wrapper {
  margin: 0 auto;
  text-align: center;
}

/* form input */
.form-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .border {
    position: relative;
    border:1px solid #dfdfdf;

    span {
      position: absolute;
      top: -12.5px;
      left: calc(50% - 25px);
      background-color: #ffffff;
      width: 50px;
      height: 50px;
      text-align: center;
      
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }
}

.form-input-onboard {
  position: relative;
  height: 100%;
  margin: 15px 0;

  input{
    border-radius: 5px;
    background-color: #f8fafc;
    font-size: 16px;
    /* line-height: 1.38; */
    letter-spacing: 0.28px;
    width: 100%;
    padding: 26px 26px 13px;
    border: solid 1px transparent;
    outline: none;
    font-family: 'AirbnbCerealApp-Medium';
    color: #18181a;

    &:focus {
      border: solid 1px $primary-color;
      background-color: rgba(248, 250, 252, 0.21);
    }
  }

  &.error input:focus {
    border: solid 1px #ff3939;
    background-color: rgba(248, 250, 252, 0.21);
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 19.5px 26px;
    color: #8d8d8d;
    cursor: text;
    transition: all 0.2s ease-out;
    height: 100%;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    // transition: opacity .1s ease-in-out,transform .1s ease-in-out;
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    font-size: 13px;
    letter-spacing: 0.23px;
    color: #444444;
    opacity: 0.74;
    transform: translateY(-11.5px);

    // opacity: .65;
    // transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
  }
}

  
/* changepass */
.form-input-changepass {
  font-family: 'AirbnbCerealApp-Medium';
  display: flex;
  flex-direction: column;
  padding-top: 15px;

  label {
    font-size: 10.4px;
    letter-spacing: 0.18px;
    color: #000000;
    padding-top: 20px;

    &.error-alert {
      color: #ff3939;
      padding-top: 5px;
      padding-bottom: 0;
      text-align: center;
      width: 335px;

      img {
        width: 10px;
        height: 10px;
      }
    }
  }

  input{
    max-width: 335px;
    max-height: 35px;
    border-radius: 2.9px;
    border: solid 0.6px #e6e6e6;
    background-color: rgba(248, 250, 252, 0.12);
    font-family: 'AirbnbCerealApp-Medium';
    font-size: 10.4px;
    color: #18181a;
    padding: 10px 10px 10px 10px;

    &::placeholder {
      opacity: 0.38;
      font-family: 'AirbnbCerealApp-Medium';
      font-size: 10.4px;
    }

    &:focus {
      border: solid 1px $primary-color;
      background-color: rgba(248, 250, 252, 0.21);
    }
  }
     
  &.error {
    input {
      &:focus {
        border: solid 1px #ff3939;
        background-color: rgba(248, 250, 252, 0.21);
      }
    }
  }

  .changepassword-show {
    cursor: pointer;
    /* line-height: 1.5; */
    border-radius: 0em;
    float: right;
    margin-top: -1.9rem;
    margin-left: 300px;
    z-index: 2;
    position: relative;
    width: 16px;

    img {
      width: 16px;
    }
  }

  .btn {
    max-width: 335px;
    display: flex;
    justify-content: flex-end;
    margin: 28px 0 10px 0;
    padding: 0;

    button {
      width: 92px;
      height: 35px;
      border-radius: 8px;
      background-color: $primary-color;
      color: #ffffff;
      font-family: 'AirbnbCerealApp-Medium';
      font-size: 12px;
      letter-spacing: 0.21px;
      text-align: center;
      border: none;
      outline: none;
    }
  }
} 
  
.forgot-pass {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: 0.23px;
  text-align: right;
  color: #4d4d4d;

  a {
    color: #4d4d4d;

    &:hover {
      color: $primary-color;
      text-decoration: none;
    }
  }
}
  
.password-show {
  cursor: pointer;
  /* line-height: 1.5; */
  border-radius: 0em;
  float: right;
  margin-top: -2.9rem;
  margin-right: 28px; 
  position: relative;
  z-index: 2;  
}
.password-show img {
  width: 18.5px;
}
  
  
/* button */

// button:hover,:focus,:active,:focus-within,:visited {
//   border: none;
//   outline: none !important;
// }
  
.submit-button-onboard {
  border-radius: 11px !important;
  background-color: $primary-color;
  color: #ffffff;
  font-size: 16px !important;
  line-height: 1.38 !important;
  letter-spacing: 0.28px !important;
  width: 100% !important;
  padding: 19.5px !important;
  border: none;
  margin: 15px 0px 0px !important;
  outline: none !important;
  font-family: 'AirbnbCerealApp-Medium' !important;
}
  
.submit-button-onboard:focus {
  outline: none;
}
  
.register-btn,
.login-btn {
  margin-top: 10px !important;
}

.app-signin {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;

  button {
    width: 100%;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: 0.28px;
    padding: 17.5px 19.8px;
    border: none;
    outline: none;
    font-family: 'AirbnbCerealApp-Medium';
    border-radius: 10px;
    background-color: rgba(38, 137, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow: auto;  
    color: #444444;

    // &:first-child {
    //   width: 100%;
    //   // margin-right: 10px;
    // }

    p {
      color: #444444;
      margin: auto;
      padding: 0 10px;

      &.r {
        color: #444444;
        margin: auto 20px;
      }
    }

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    &:focus {
      outline: none;
    }
  }
}
  
.button-back {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 4%;
}


#privacypolicy {
  padding: 40px 10px;
  line-height: 26px;

  p {
    font-family: 'AirbnbCerealApp-Book';
    font-size: 16px; 
    color: rgba(24, 24, 26, 0.7);  
  }
}
