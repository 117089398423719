
.modal-verify-email,
.modal-change-email,
.modal-verify-email-sent {
    text-align: center;

    .modal-header {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        border: none;
        padding: 20px;
        padding-bottom: 0;
    }

    .modal-body {
        padding: 40px
    }
    
    .title {
        text-align: center;
        margin-bottom: 20px;
    }

    .content {
        font-weight: 500;

        button {
            // margin-top: 20px;
            // margin: 10px 0;
        }

        .separator {
            // margin-top: 20px;
            margin-top: 15px;
        }
    }

    .button-group {
        display: flex;
        // justify-content: flex-end;
        justify-content: center;
        margin: 15px 5px 5px;
        // margin-top: 10px;

        button {
            min-height: 45px;
            min-width: 50%;
            max-width: 100%;
            width: fit-content;
            margin: 0 5px;
            font-size: 14px;
        }
    }
}

.modal-verify-email,
.modal-verify-email-sent {
    // .modal-content {
    //     padding: 20px 0;
    // }
}