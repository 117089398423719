.documentation-card {
    width: 100%;
    padding-top: 127%; /* 100:1127 Aspect Ratio */
    position: relative; /* If you want text inside of it */
    margin: 20px 0;
    border-radius: 25.6px;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    cursor: pointer;
    transform: translateY(0px);
    transition: 0.3s ease transform;

    &:hover {
        transform: translateY(-10px);
    }

    .title {
        position: absolute;
        z-index: 1;
        top: 10%;
        left: 10%;
        width: 60%;
        // font-family: 'AirbnbCerealApp-medium';
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: -0.25px;
        color: #3372CA;
    }

    .download-btn {
        position: absolute;
        top: 40%;
        left: 40%;
        width: 20%;

    }

    .bg-img {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 45%;
        border-bottom-right-radius: 25.6px;
    }
}