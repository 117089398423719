// Landing
.main {
  #navbar-feature,
  #navbar-service,
  .testimony-container,
  .faq-container,
  #content-bottom,
  #landingpage-footer,
  #content-top-landing-feature {
    display: none;
  }

  #content-top-landing-feature {
    height: fit-content;
    padding: 80px 0 120px;
  }

  #landing-page-stt,
  #landing-page-tts {
    margin-top: 0;
  }

  #landing-page-notulite {
    display: flex;
    flex-wrap: wrap;
    padding: 5vh 5vw;
    background-color: #fafbfc;

    #content-top-landing-feature {
      width: 50%;
      background: unset;
      
      .content-landing-feature-paragraf {
        background-color: #f1f2f3;
        color: #000000;
        border-radius: 25.6px;
        padding: 40px 30px 80px;

        h1 {
          line-height: unset;
        }
      }
    }

    #content-service-menu {
      width: 50%;
      padding: 50px;
      background-color: #f1f2f3;
      border-radius: 15px;

      h2 {
        font-weight: bold;
        font-size: 25px;
        line-height: unset;
        letter-spacing: unset;
      }

      .content-service-menu-b {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        padding-top: 50px;

        .content-service-menu-item {
          max-width: 100%;
          min-height: unset;
          width: 100%;

          img {
            display: none;
          }

          h3 {
            padding: 0;
          }

          p {
            margin: 0;
          }

          &:hover {
            background-color: #ffffff;
            transform: unset;
            cursor: unset;

            h3 {
              color: #000000;
            }

            p {
              color: rgba(24, 24, 26, 0.7);
            }
          }
        }
      }
    }
  }
}

.main-bg {
  position: absolute;
  height: 60vh;
  width: 100%;
  padding-left: 100px;
  background-color: #3372CA;
  border-bottom-left-radius: 50px;
}

.main-container {
  position: relative;
  padding: 3vh 3vw;
  // min-width: 558px;
  width: 100%;

  
  .main-header {
    // max-width: 1200px;
    // margin: 0 auto;
    position: relative;
    padding-bottom: 7vh;

      .main-bg {
          // 6vh 10vw
          top: -6vh;
          left: -6vw;
          height: calc(100% + 4vh);
          // width: calc(100% + 20vw);
          width: calc(100% + 12vw);
      }
      
      h3 {
        position: relative;
        font-family: 'AirbnbCerealApp-Bold';
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: 0.35px;
        color: #ffffff;
      }

      p {
        position: relative;
        font-family: 'AirbnbCerealApp-Book';
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: 0.09px;
        color: #ffffff;
      }

      a {
        position: relative;
      }
  }

  .main-card-container {
    // max-width: 1200px;
    // margin: 0 auto;

    .row {      

      .dashboard-card-col {
        display: flex;
        flex-direction: column;
        padding: 15px;  

        .services-card {
            height: fit-content;
            padding: 25px 30px !important;
            margin-bottom: 30px;
            
            h3 {
                margin-bottom: 10px;
            }
            p {
                font-size: 18px;
                margin-bottom: 10px;

                &.title {
                    font-weight: 600;
                    margin-bottom: 0;
                }
            }

            iframe {
                min-height: 300px;
                margin: 5px 0 10px;
            }
        }

        .services-card:last-child {
            margin-bottom: 0;
        }
      }

    }
  }
}

.usage-history-list-head {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: solid 2px #f8f9fa;
  padding: 20px 0;
  align-items: center;
  
  .title {
    font-family: 'AirbnbCerealApp-Bold';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    color: #c1c1c1;
    padding-bottom: 5px;
  }
}

.usage-history-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: solid 2px #f8f9fa;
  padding: 20px 0;
  align-items: center;

  .content {
    font-family: 'AirbnbCerealApp-Medium';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(24, 24, 26, 0.7);    
  }

  .status {
    min-width: 75.5px;
    border-radius: 11.6px;
    font-family: 'AirbnbCerealApp-Bold';
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;
    padding: 7px 12px;
    width: fit-content;
    word-spacing: 5px;

    &.success {
      color: #7ed321;
      background-color: rgba(126, 211, 33, 0.1);
    }
    &.failed {
      color: #ff3939;
      background-color: rgba(255, 57, 57, 0.1);
    }
  }
}

.status-card {
  padding: 0 15px;
  margin: 0;

  .status-card-container {
    min-height: 152px;
    border-radius: 25px;
    background-color: #ffffff;
    padding: 18px 17px 38px 40px;
    margin-bottom: 30px;

    .top {
      display: flex;
      align-items: center;
      height: 47px;
      .top-status {
        opacity: 0.9;
        font-family: 'AirbnbCerealApp-Medium';
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.24px;
        color: #c1c1c1;
      }
      img {
        margin: 0 0 0 auto;
      }
    }
    .body {
      font-family: 'AirbnbCerealApp-Medium';
      font-size: 35px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.25px;
      color: #3372CA;
      display: flex;
      .sub-body {
        font-size: 20px;
        padding-left: 0.3em;
        margin: auto 0 0.2em 0;
      }
    }
  }
}


#download_link {
  margin: 40px auto 0 auto;
  width: fit-content;
}