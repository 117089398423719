
.side-u-wrapper {
    padding: 45px 96px 100px 96px;
    background-color: #ffffff;
    width: 100%;
}

.side-u-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 68px;
    /* or 170% */
    text-align: center;
    letter-spacing: -0.663461px;

    color: #202020;
    margin: 80px auto;
}
.side-u-container {
    display: flex;
    flex-direction: column;
    .list-item {
        display: flex;
        flex-direction: column;        
        margin-bottom: 30px;
        max-width: 358px;
        position: relative;
        cursor: pointer;
        &.border-b {
            border-bottom: 1px solid #F0F2F6;
        }
        #arrow {
            width: auto;
            height: 14px;
            position: absolute;
            margin-top: 8px;
        }
        .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            letter-spacing: 0.14881px;
            color: #202020;
            padding-left: 20px;
            &.active {
                color:#2E8CFB;
            }            
        }
        .text-p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
            padding-left: 20px;
            /* or 150% */
            letter-spacing: 0.119048px;
            color: #81888F;
            margin-bottom: 30px;
            max-width: 270px;         
        }
    }
}

.side-ur-container {
    background: #FDFBFB;
    border-radius: 22px;
    height: 100%;
    padding: 31.7px 35px;
    .side-ur-textarea-wrapper {
        background: #FFFFFF;
        border: 1px solid #979797;
        border-radius: 25px;
        height: 100%;
        padding: 46px 19px 46px 39px;
        position: relative;
        #side-ur-textarea {
            width: 100%;
            height: 90%;
            font-family: 'Inter';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 36px;
            /* or 180% */
            letter-spacing: 0.136905px;
            color: #202020;
            border: none;
            margin-bottom: auto;
            padding-right: 10px;
            overflow: auto;
             /* width */
            &::-webkit-scrollbar {
                width: 3.9px;
                cursor: grab;
            }
                
            /* Track */
            &::-webkit-scrollbar-track {
                background-color: #ffffff;
            }
                
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #c1c1c1;
                width: 3.9px;
                // height: 10px;
                border-radius: 3.5px;
                cursor: grab;
            }
        }
        
    }
}
.audio-play-wrap {
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 20px;
    padding-left: 20px;
    max-width: 270px;

    .progress {
        width: 100%;
        height: 11px;
    }
    #play-icon {
        width: 38px;
        height: 38px;
        margin-right: 20px;
    }
    #audio-play {
        #audio-play-icon {
            width: 38px;
            height: 38px;
        }
    }    
}

.audio-play-wrap-full {
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 20px;
    #audio-play {
        #audio-play-icon {
            width: 38px;
            height: 38px;
        }
    }
    
}

@media (max-width:856px) {
    .side-u-container {
        display: flex;
        flex-direction: column;
        .list-item {
            display: flex;
            flex-direction: column;        
            margin-bottom: 30px;
            max-width: 358px;
            position: relative;
            cursor: pointer;
            &.border-b {
                border-bottom: 1px solid #F0F2F6;
            }
            #arrow {
                width: auto;
                height: 14px;
                position: absolute;
                margin-top: 8px;
            }
            .title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 21px;
                line-height: 23px;
                letter-spacing: 0.14881px;
                color: #202020;
                padding-left: 20px;
                &.active {
                    color:#2E8CFB;
                }            
            }
            .text-p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 23px;
                padding-left: 20px;
                /* or 150% */
                letter-spacing: 0.119048px;
                color: #81888F;
                margin-bottom: 30px;
                max-width: 270px;         
            }
        }
    }
    
    .side-ur-container {
        background: #FDFBFB;
        border-radius: 22px;
        height: 100%;
        padding: 26.7px 30px;
        min-height: 500px;
        .side-ur-textarea-wrapper {
            background: #FFFFFF;
            border: 1px solid #979797;
            border-radius: 25px;
            height: 100%;
            padding: 36px 19px 36px 29px;
            position: relative;
            #side-ur-textarea {
                font-size: 18px;                
                line-height: 26px;
            }
        }
    }
}

@media (max-width:750px) {
    .side-u-wrapper {
        padding: 45px 8vw 100px 8vw;  
    }
    .side-ur-container {
        background: #FDFBFB;
        border-radius: 22px;
        height: 100%;
        min-height: 500px;
        padding: 26.7px 30px;
        .side-ur-textarea-wrapper {
            background: #FFFFFF;
            border: 1px solid #979797;
            border-radius: 25px;
            height: 100%;
            padding: 36px 29px 46px;
            position: relative;
            #side-ur-textarea {
                font-size: 18px;                
                line-height: 26px;
                
            }
            .audio-play-wrap-full {
                display: flex;
                align-items: center;
                padding-top: 10px;
                padding-bottom: 30px;
                margin-top: -18px;
                // margin-bottom: 80px;
            }
        }
    }
}


// @media (max-width:500px) {
//     .side-u-wrapper {
//         padding: 45px 1vw 100px !important;
//     }
// }