
#services-container {
  
    .btn-close {
        margin: 0 0 0 auto;
        cursor: pointer;
    }
    
    .row {
        // width: 100%;
    }

    .services-col {
        
        .title-col {
            padding: 15px 30px;
            font-size: 30px;
            color: #ffffff;
            border-radius: 8px 8px 0 0;
            border: 1px solid #ffffff;
            border-color: #3372CA;
            background-color: #3372CA;
        }
        
        .content-col {
            padding: 30px 30px;
            margin-top: -1px;
            border-radius: 0 0 8px 8px;
            border: 1px solid #ffffff;
            border-color: #3372CA;

        }
    }
    
    .services-card-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        margin-bottom: 30px;
    
        &:last-child {
            margin-bottom: 0;
        }

        .title {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 16px;
            font-size: 24px;
            border-bottom: 1px solid #ffffff;
            color: #ffffff;
            margin: 0;
            border-radius: 8px 8px 0 0;
            border: 1px solid #ffffff;
            border-color: #3372CA;
            background-color: #3372CA;
    
            .badge {
                margin-left: 10px;
            }
        }
        .content {
            width: 100%;
            padding: 15px;
            margin-top: -1px;
            font-size: 18px;
            border-radius: 0 0 8px 8px;
            border: 1px solid #ffffff;
            border-color: #3372CA;
            justify-content: center;
            .row {
                width: 100%;
                height: fit-content;
                margin: 0;
        
                .service-card-col {                   
                    padding: 15px;
                }
            }
    
            .docs-link {
                padding: 10px 15px;
        
                a {
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                    color: #3372CA;
        
                    .badge {
                    margin-right: 10px;
                    }
                }
            }
        }
    }

    .services-card {        
        transition: all 0.2s;

        
        &:hover {
            background-color: #75e8e7;
            // background-color: #3372CA;

            h3 {
                margin-bottom: 0;
                // color: #ffffff;
            }
            
            p {
                display: none;
                // color: #ffffff;
            }
        }

        h3 {
            margin-bottom: 0;
        }
        
        p {
            display: none;
        }
    }
}