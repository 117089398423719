.dashboard {
    font-family: 'AirbnbCerealApp-Book', sans-serif;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
        
    .main {
        max-height: 100vh;
        width: 100%;
        position: relative;
        background-color: #f5f6fa;
        overflow: auto;
    }

    &.mobile {
        padding-top: 62px;
        // display: flex;
        // flex-direction: column;
        // height: 100vh;
        overflow: auto;

        .main {
            max-height: unset;
            overflow: hidden;
        }
    }
}
